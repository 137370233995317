import {combineReducers} from "redux";
import {reducer as reduxFormReducer} from "redux-form";
import ApplicationReducer from "./ApplicationReducer";
import AuthReducer, {loadAuthDatafromCookie} from "./AuthReducer";
import ContentReducer from "./ContentReducer";
import DashboardReducer, {loadDashboardDataFromCookie} from "./DashboardReducer";
import ErrorReducer from "./ErrorReducer";
import LoanReducer, {loadLoanDataFromCookie} from "./LoanReducer";
import OrganizationReducer from "./OrganizationReducer";
import ProgressReducer from "./ProgressReducer";
import * as field from "../../shared/constant/field";
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";
import InsuranceReducer, {loadInsuranceDataFromCookie} from "./InsuranceReducer";
import mcIdentityReducer, {loadMcIdentityDataFromCookie} from "./McIdentityReducer";
import AchReducer, {loadAchDataFromCookie} from "./AchReducer";

export const fromCookie = {
    loadAuthDatafromCookie,
    loadDashboardDataFromCookie,
    loadLoanDataFromCookie,
    loadInsuranceDataFromCookie,
    loadMcIdentityDataFromCookie,
    loadAchDataFromCookie
};

const applicationPersistConfig = {
    key: field.REDUCER_NODE_APPLICATION,
    storage,
    blacklist: [field.USERLOANAPPINFO]
};

const rootReducer = combineReducers({
    [field.REDUCER_NODE_AUTH]: AuthReducer,
    [field.REDUCER_NODE_APPLICATION]: persistReducer(applicationPersistConfig, ApplicationReducer),
    [field.REDUCER_NODE_ERROR]: ErrorReducer,
    [field.REDUCER_NODE_DASHBOARD]: DashboardReducer,
    [field.REDUCER_NODE_LOAN]: LoanReducer,
    [field.REDUCER_NODE_FORM]: reduxFormReducer,
    [field.REDUCER_NODE_APP_PROGRESS]: ProgressReducer,
    [field.REDUCER_NODE_CONTENT]: ContentReducer,
    [field.REDUCER_NODE_ORGANIZATION]: OrganizationReducer,
    [field.REDUCER_NODE_INSURANCE]: InsuranceReducer,
    [field.REDUCER_NODE_MC_IDENTITY]: mcIdentityReducer,
    [field.REDUCER_NODE_ACH]: AchReducer
});

export default rootReducer;
