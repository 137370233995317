import _ from 'lodash'

import * as cookieUtility from '../../shared/utility/cookieutility'
import * as constant from '../../shared/constant/constant'
import {LOAN as field} from '../../shared/constant/field'
import * as actionTypes from '../action/actionTypes'
import {setDataTags} from "../../views/OneSignal/PushNotification";
import moment from "moment";
import * as utility from "../../shared/utility/utility";
import * as tagConstants from "../../shared/constant/tagConstants";

const initialState = {
    [field.LOAN_LIMITS]: {
        [field.LOAN_LIMITS__MIN_AMOUNT]: null,
        [field.LOAN_LIMITS__MAX_AMOUNT]: null
    },
    [field.LOAN_LIMITS_BY_ORG_AND_STATE]: {
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__ID]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__STATE_NAME]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__STATE_CODE]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__MIN_AMOUNT]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__MAX_AMOUNT]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__STEP_SIZE]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_SUPPORTED]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ACTIVE]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_PENDING_LICENSE]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ORGANIC_RC_OPTIMIZATION_ENABLED]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_NC_AUTO_FUND_ENABLED]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_ENABLED]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_PAYROLL_RISK_CALL_ENABLED]: null,
    },
    [field.PAYMENT_INFO_DETAILS]: {
        [field.CURRENT_BALANCE]: null,
        [field.LOAN_DUE_DATE]: null,
        [field.PAYMENT_DUE]: null,
        [field.NOTIFICATION_CODE]: null,
        [field.LAST_PAYMENT]: null,
        [field.PREVIOUS_PAYMENT_DUE]: null,
        [field.AMOUNT_PAST_DUE]: null,
        [field.PREVIOUS_PAYMENT_DUE_DATE]: null,
        [field.PAYMENT_INFO_NOT_AVAILABLE]: null,
        [field.THEORETICAL_SEP_AMOUNT]: null,
        [field.HAS_FUTURE_COLLECTION]: null,
        [field.HAS_GAP_TODO_AMOUNT]: null,
        [field.IS_ELIGIBLE_FOR_CONCESSION]: null,
        [field.REDUCED_PAST_DUE_AMOUNT]: null,
        [field.REDUCED_CURRENT_BALANCE]: null,
        [field.SHOULD_SHOW_PAYMENT_DUE_TODAY]: null,
    },
    [field.PAYMENT_TRANSACTION_INFO]: {
        [field.TRANSACTION_REF_NUMBER]:null,
        [field.AUTH_CODE]:null,
        [field.CARD_LAST_FOUR_DIGIT]:null,
        [field.TOTAL_AMOUNT]:null,
        [field.TRANSACTION_DATE_TIME]:null
    },
    [field.ACTIVE_LOAN]: {
        [field.BASE_LOAN_ID]: null,
        [field.LOAN_HEADER_ID]: null,
        [field.LOAN_CUSTOMERID]: null,
        [field.ACTIVE_LOAN_AMOUNT]: null,
        [field.LOAN_CREATED_DATE]: null,
        [field.LOAN_ORIGINATION_DATE]: null,
        [field.LOAN_EFFECTIVE_DATE]: null,
        [field.LOAN_DUE_DATE]: null,
        [field.LOAN_PAID_OFF_DATE]: null,
        [field.LOAN_WITHDRAWN_DATE]: null,
        [field.CANCELLATION_TYPE]: null,
        [field.LOAN_STATUS]: null,
        [field.LOAN_LEAD_SEQUENCE_ID]: null,
        [field.LOAN_LMS_ENTITY_ID]: null,
        [field.LOAN_ENTITY_NAME]: null,
        [field.LOAN_LOAN_HEADER_ID]: null,
        [field.LOAN_CAMPAIGN_NAME]: null,
        [field.LOAN_USER_ID]: null,
        [field.LOAN_TYPE]: null,
        [field.LOAN_IS_ONLINE_DRAW_ENABLED]: null,
        [field.LOAN_IS_ONLINE_PAYMENT_ENABLED]: null,
        [field.LOAN_IS_NC_AUTO_FUND_FLOW]: false,
        [field.LOAN_IS_AUTO_FUND]: false,
        [field.LOAN_IS_NOBANKCALL]: null,
    },
    /*
        Sample Instance
        {
            [field.LOAN_DOCS_DOCUMENT_VERSION_ID]: null,
            [field.LOAN_DOCS_DOCUMENT_SUBJECT]: null,
            [field.LOAN_DOCS_DOC_ORDER]: null,
            [field.LOAN_DOCS_DOCUMENT_NAME]: null,
            [field.LOAN_DOCS_COMPLETED]: null,
            [field.LOAN_DOCS_CONTENT]: null,
            [field.LOAN_DOCS_SIGNATURE_REQUIRED]: null,
            [field.LOAN_DOCS_SSN_LAST_FOUR]: null,
        }
    */
    [field.LOAN_DOCS]: [],
    [field.ACTIVE_LOAN_SUMMARY]: {
        [field.BASE_LOAN_ID]: null,
        [field.CREDIT_LIMIT]: null,
        [field.OUTSTANDING_PRINCIPAL]: null,
        [field.AVAILABLE_CREDIT]: null,
        [field.LOAN_STATUS]: null,
        [field.NON_WITHDRAWN_LOAN_STATUS]: null,
        [field.LOAN_EXTENDED_STATUS]: null,
        [field.ACCOUNT_SUMMARY]: {
            [field.LAST_DRAW_AMOUNT]: null,
            [field.LAST_DRAW_DATE]: null,
            [field.LAST_PAYMENT_AMOUNT]: null,
            [field.LAST_PAYMENT_DATE]: null,
            [field.NEXT_PAYMENT_DATE]: null
        },
        [field.STATEMENT_SUMMARY]: {
            [field.STATEMENT_DATE]: null,
            [field.STATEMENT_BALANCE]: null,
            [field.MINIMUM_PAYMENT_DUE]: null,
            [field.PAYMENT_DUE_DATE]: null,
            [field.AMOUNT_PAST_DUE]: null
        },
        [field.DRAW_ATTEMPT_DETAILS]: {
            [field.SCHEDULED_DATE]: null,
            [field.DRAW_AMOUNT]: 0,
            [field.DRAW_SCHEDULED]: false
        },
        [field.PENDING_DRAW]: {
            [field.SCHEDULED_DATE]: null,
            [field.DRAW_AMOUNT]: 0,
            [field.DRAW_SCHEDULED]: false
        },
        [field.LOAN_TYPE]: null,
        [field.IS_DISABLE_DRAW_DUE_TO_INACTIVITY]: null
    },
    /*
    Sample Instance
    {
        [field.PSI_ID]: null,
        [field.PAYMENT_DATE]: null,
        [field.PAYMENT_AMOUNT]: null,
        [field.PAYMENT_STATUS]: null,
        [field.IS_COLLECTION]: false
    }
    */
    [field.PAYMENT_SCHEDULE_ITEMS]: [],
    [field.NEW_CREDIT_LIMIT]: null,
    [field.OLD_CREDIT_LIMIT]: null,
    [field.IS_PSI_RETURNED]: null,
    [field.TDC_LOAN]: {
        [field.TDC_APPL_NO]: null,
        [field.TDC_APPL_STATUS]: null,
        [field.TDC_LOAN_STATUS]: null,
        [field.TDC_MERCHSTORE_ID]: null,
        [field.TDC_USER_ID]: null,
        [field.TDC_LOAN_REDIRECT_PAGE]: null
    },
    [field.LOAN_IS_COMPLIANCE_ALERT_ACTIVE]: null
};

/**
 * This function is used in react/src/index.jsx to rehydrate LoanState from cookie.
 * @returns {{[p: string]: {}}}
 */
export const loadLoanDataFromCookie = () => {
    let loanstate = initialState;
    let mkWebLoanData = cookieUtility.getCookie(constant.COOKIE_MKWEBLOAN_DATA);
    loanstate = {...loanstate, ...mkWebLoanData};
    return loanstate;
};

const _setLoanReducerCookie = state => {
    let cookieMkwebLoanData = _.cloneDeep(state);
    cookieUtility.setCookie(constant.COOKIE_MKWEBLOAN_DATA, JSON.stringify(cookieMkwebLoanData));
};

const _update = (state, data) => {
    return {...state, ...data};
};

const _updateByTopField = (state, data, field) => {
    state = _update(state, {
        [field]: {
            ...state[field],
            ...data
        }
    });
    _setLoanReducerCookie(state);
    return state;
};

const _updateLoanLimitsByOrgAndState = (state, data) => {
    return _updateByTopField(state, data, field.LOAN_LIMITS_BY_ORG_AND_STATE);
};

const resetLoanReducer = (state, action) => {
    let updatedState = _.cloneDeep(initialState);

    return updatedState;
};

const _setLoanDataTagsForNotification=(state)=>{
    let loanData={};
    if(!utility.isNullOrUndefined(state[field.ACTIVE_LOAN][field.LOAN_CREATED_DATE])){
        let loanCreatedDate=moment(state[field.ACTIVE_LOAN][field.LOAN_CREATED_DATE]).unix();
        loanData[tagConstants.TAG_LOAN_CREATED_DATE]=loanCreatedDate;
    }
    if(!utility.isNullOrUndefined(state[field.ACTIVE_LOAN][field.LOAN_ORIGINATION_DATE])){
        let origintedDate=moment(state[field.ACTIVE_LOAN][field.LOAN_ORIGINATION_DATE]).unix();
        loanData[tagConstants.TAG_DATE_SINCE_LOAN_ORIGINATED]=origintedDate;
    }
    if(!utility.isNullOrUndefined(state[field.ACTIVE_LOAN][field.LOAN_PAID_OFF_DATE])){
        let paidOffDate=moment(state[field.ACTIVE_LOAN][field.LOAN_PAID_OFF_DATE]).unix();
        loanData[tagConstants.TAG_PAID_OFF_DATE]=paidOffDate;
    }
    if(!utility.isNullOrUndefined(state[field.ACTIVE_LOAN][field.LOAN_WITHDRAWN_DATE])){
        let withdrawnDate=moment(state[field.ACTIVE_LOAN][field.LOAN_WITHDRAWN_DATE]).unix();
        loanData[tagConstants.TAG_DATE_SINCE_WITHDRAW]=withdrawnDate;
        loanData[tagConstants.TAG_REASON_FOR_WITHDRAW]=state[field.ACTIVE_LOAN][field.EXTENDED_STATUS];
    }
    loanData[tagConstants.TAG_LOAN_STATUS]=state[field.ACTIVE_LOAN][field.LOAN_STATUS];
    loanData[tagConstants.TAG_LOAN_TYPE]=state[field.ACTIVE_LOAN][field.LOAN_TYPE];
    loanData[tagConstants.TAG_CUSTOMER_ID] = state[field.ACTIVE_LOAN][field.LOAN_CUSTOMERID];
    loanData[tagConstants.TAG_APPLICATION_STARTED] = true;
    loanData[tagConstants.TAG_APPLICATION_COMPLETED] = true;
    loanData[tagConstants.TAG_IS_PERSONAL_INFO_SUBMITTED] = true;
    loanData[tagConstants.TAG_IS_USER_CREATED_PASSWORD] = true;
    setDataTags(loanData);
}
const _setLoanDataTagsForLoanSummry=(state)=>{
    let loanSummaryData={};
    if (!utility.isNullOrUndefined(state[field.ACTIVE_LOAN_SUMMARY][field.ACCOUNT_SUMMARY][field.NEXT_PAYMENT_DATE])) {
        let nextPaymentDate = moment(state[field.ACTIVE_LOAN_SUMMARY][field.ACCOUNT_SUMMARY][field.NEXT_PAYMENT_DATE]).unix();
        loanSummaryData[tagConstants.TAG_NEXT_PAYMENT_DATE] = nextPaymentDate;
        loanSummaryData[tagConstants.TAG_NEXT_PAYMENT_DATE_ISO] = utility.toDateFormatMMDDYYYY(state[field.ACTIVE_LOAN_SUMMARY][field.ACCOUNT_SUMMARY][field.NEXT_PAYMENT_DATE]);
    }
    setDataTags(loanSummaryData);
}
const getLoanLimitsSuccess = (state, action) => {
    let data = action.payload.response.data.data
        ? {
              [field.LOAN_LIMITS__MAX_AMOUNT]: action.payload.response.data.data.limit,
              [field.LOAN_LIMITS__MIN_AMOUNT]: action.payload.response.data.data.minimum
          }
        : undefined;
    state = {
        ...state,
        [field.LOAN_LIMITS]: data
    };
    _setLoanReducerCookie(state);
    return state;
};

const getLoanLimitsByOrgAndStateSuccess = (state, action) => {
    let data = null;
    if(action.type === actionTypes.GET_LOAN_LIMITS_BY_ORG_AND_STATE_SUCCESS) {
        data = action.payload.response.data[0];
    } else if(action.type === actionTypes.GET_LANDING_PAGE_SUCCESS) {
        data = action.payload.response.data.stateOrganization;
    } else {
        return state;
    }
    if(data === null) {
        return state;
    }
    state = _updateLoanLimitsByOrgAndState(state, {
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__ID]: data.id,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__STATE_NAME]: data.state.stateName,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__STATE_CODE]: data.state.stateCode,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__MIN_AMOUNT]: data.minAmount,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__MAX_AMOUNT]: data.maxAmount,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__STEP_SIZE]: data.stepSize,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_SUPPORTED]: data.isSupported,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ACTIVE]: data.isActive,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_PENDING_LICENSE]: data.isPendingLicense,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD]: data.isLoanDocsinDashboard,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ORGANIC_RC_OPTIMIZATION_ENABLED]: data.isOrganicRCOptimizationEnabled,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_NC_AUTO_FUND_ENABLED]: data.isNCAutoFundEnabled,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_PAYROLL_RISK_CALL_ENABLED]: data.isPayrollRiskCallEnabled,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_ENABLED]: data.isOneTimePasswordEnabled,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_RISK_CALL_ENABLED]: data.isOneTimePasswordRiskCallEnabled,
    });
    _setLoanReducerCookie(state);
    return state;
};

const getLoanLimitsByOrgAndStateFailure = (state, action) => {
    return _updateLoanLimitsByOrgAndState(state, {
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__ID]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__STATE_NAME]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__STATE_CODE]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__MIN_AMOUNT]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__MAX_AMOUNT]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__STEP_SIZE]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_SUPPORTED]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ACTIVE]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_PENDING_LICENSE]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ORGANIC_RC_OPTIMIZATION_ENABLED]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_PAYROLL_RISK_CALL_ENABLED]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_ENABLED]: null,
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_RISK_CALL_ENABLED]: null,
    });
};

const resetStateIsActive = (state, action) => {
    return _updateLoanLimitsByOrgAndState(state, {
        [field.LOAN_LIMITS_BY_ORG_AND_STATE__IS_ACTIVE]: null,
    });
};

const getActiveLoanSuccess = (state, action) => {
    let data = action.payload.response.data;

    //API inconsitencies require these two lines. Need to unify these going forward
    data[field.LOAN_STATUS] =
        data.status || data[field.LOAN_STATUS] ? (data.status || data[field.LOAN_STATUS]).toLowerCase() : undefined;
    delete data.status;

    state = {
        ...state,
        ...{
            [field.ACTIVE_LOAN]: data
        }
    };
    _setLoanReducerCookie(state);
    return state;
};

const updateActiveLoanDetails = (state, action) => {

    if(action.payload.response.data.isPSIReturned) {
        state = {
            ...state,
                [field.IS_PSI_RETURNED]: action.payload.response.data.isPSIReturned
            }
        }


    let data = action.payload.response
        ? action.payload.response.data.customerActiveLoanDetails
        : action.payload.data.customerActiveLoanDetails;

    if(!data){
        return state;
    }

    //API inconsitencies require these two lines. Need to unify these going forward
    data[field.LOAN_STATUS] =
        data.status || data[field.LOAN_STATUS] ? (data.status || data[field.LOAN_STATUS]).toLowerCase() : undefined;
    delete data.status;

    state = {
        ...state,
        [field.ACTIVE_LOAN]: {
            ...data,
            [field.LOAN_IS_ONLINE_PAYMENT_ENABLED] : action.payload.response && action.payload.response.data.userLoanAppInformationDTO ?
                action.payload.response.data.userLoanAppInformationDTO.isOnlinePaymentEnabled:null
        },
        [field.LOAN_IS_COMPLIANCE_ALERT_ACTIVE]: action.payload.response.data.isComplianceAlertActive,
    };
    _setLoanReducerCookie(state);
    _setLoanDataTagsForNotification(state);
    return state;
};

const updateActiveLoanEntityId = (state, action) => {
    state = {
        ...state,
        ...{
            [field.ACTIVE_LOAN]: {
                ...state[field.ACTIVE_LOAN],
                ...{
                    [field.LOAN_LMS_ENTITY_ID]: action.payload.response.data.entityId
                }
            }
        }
    };
    _setLoanReducerCookie(state);
    return state;
};

const getActiveLoanSummarySuccess = (state, action) => {
    let data = action.payload.response.data;

    //API inconsitencies require these two lines. Need to unify these going forward
    data[field.LOAN_STATUS] =
        data.status || data[field.LOAN_STATUS] ? (data.status || data[field.LOAN_STATUS]).toLowerCase() : undefined;
    delete data.status;

    state = {
        ...state,
        ...{
            [field.ACTIVE_LOAN_SUMMARY]: {
                ...state[field.ACTIVE_LOAN_SUMMARY],
                ...data
            }
        }
    };
    _setLoanReducerCookie(state);
    _setLoanDataTagsForLoanSummry(state);
    return state;
};

const getLatestNonWithdrawnLoanSummarySuccess = (state, action) => {
    let data = action.payload.response.data;
    let _state = state;
    if (data !== null && data !== undefined && data !== "") {
        data[field.NON_WITHDRAWN_LOAN_STATUS] =
            data.status || data[field.LOAN_STATUS] ? (data.status || data[field.LOAN_STATUS]).toLowerCase() : undefined;
        _state = {
            ...state,
            ...{
                [field.ACTIVE_LOAN_SUMMARY]: {
                    ...state[field.ACTIVE_LOAN_SUMMARY],
                    [field.NON_WITHDRAWN_LOAN_STATUS]: data[field.NON_WITHDRAWN_LOAN_STATUS]
                }
            }
        };
    }

    return _state;
};

const getLoanDocsByCustomerSuccess = (state, action) => {
    state = {
        ...state,
        ...{
            [field.ACTIVE_LOAN]: {
                ...state[field.ACTIVE_LOAN],
                ...{
                    [field.BASE_LOAN_ID]: action.payload.response.data.baseLoanId,
                    [field.LOAN_HEADER_ID]: action.payload.response.data.loanHeaderId
                }
            },
            [field.LOAN_DOCS]: [...action.payload.response.data.entity]
        }
    };
    _setLoanReducerCookie(state);
    return state;
};

/**
 * Helper method which does followin steps
 * Step 1) Finds the document which matches to documentVersionId from Loan_Docs array of reducer
 * Step 2) Update that specific document with the given contentToUpdate and return a new state
 *
 * @param state
 * @param documentVersionId
 * @param contentToUpdate
 * @returns {*}
 * @private
 */
const _updateLoanDocContent = (state, documentVersionId, contentToUpdate) => {
    let updateLoanDocIndex = _.findIndex(state[field.LOAN_DOCS], function(val) {
        return val[field.LOAN_DOCS_DOCUMENT_VERSION_ID] === documentVersionId;
    });

    // If update loan doc index found then, go ahead and update fields.
    if (updateLoanDocIndex >= 0) {
        let newLoanDocs = _.cloneDeep(state[field.LOAN_DOCS]);
        newLoanDocs[updateLoanDocIndex] = {
            ...newLoanDocs[updateLoanDocIndex],
            ...contentToUpdate
        };
        state = {
            ...state,
            ...{
                [field.LOAN_DOCS]: newLoanDocs
            }
        };
        _setLoanReducerCookie(state);
        return state;
    } else {
        return state;
    }
};

const getLoanDocContentByDocVersionIdSuccess = (state, action) => {
    let responseData = action.payload.response.data;
    return _updateLoanDocContent(state, responseData.documentVersionId, responseData);
};

const saveLoanAgreement = (state, action) => {
    return _updateLoanDocContent(state, action.payload.data.docVersionId, {
        [field.LOAN_DOCS_COMPLETED]: false
    });
};

const saveLoanAgreementSuccess = (state, action) => {
    let responseData = action.payload.response.data;
    if (responseData.entity.length > 0) {
        let changedEntity = responseData.entity[0];
        // Upon successfully saving data in the backend, backend changes the flag completed=1 for a specific
        // documentVersionId. Similary, front end is also changing completed flag to reflect the same
        // Marking current loan doc content = null, because we dont want to show the outdated loan doc content.
        // Next time user request to see the loan doc content, then make another request to grab the latest loan doc content
        // from server
        return _updateLoanDocContent(state, changedEntity.documentVersionId, {
            [field.LOAN_DOCS_COMPLETED]: true,
            [field.LOAN_DOCS_CONTENT]: null
        });
    }
};

const getPaymentScheduleSuccess = (state, action) => {
    let responseData = action.payload.response.data;
    return {
        ...state,
        [field.PAYMENT_SCHEDULE_ITEMS]: responseData
    };
};

const getPaymentInfoSuccess = (state, action) => {
    let responseData = action.payload.response.data;
    return {
        ...state,
        [field.PAYMENT_INFO_DETAILS]: responseData
    };
};

const getPaymentTransactionInfoSuccess = (state, action) => {
    return _updateByTopField(state, action.payload.response.data, field.PAYMENT_TRANSACTION_INFO);
};

const acceptCreditLimitIncreaseSuccess = (state, action) => {
    let responseData = action.payload.response.data;
    return {
        ...state,
        [field.NEW_CREDIT_LIMIT]: responseData.data.newCreditLimit,
        [field.OLD_CREDIT_LIMIT]: responseData.data.oldCreditLimit
    };
};

const getTDCRedirectPageSuccess = (state, action) => {
    return {
        ...state,
        [field.TDC_LOAN]: action.payload.response.data
    };
};

const getLocLowerFeeLevelSuccess = (state, action) => {
    return {
        ...state,
        [field.FEE_LEVEL]: action.payload.response.data
    };
};

const getCustomerBankingInformationSuccess = (state, action) => {
    return {
        ...state,
        [field.BANKING_INFO]: action.payload.response.data
    };
}

const retrieveInterestRateSuccess = (state, action) => {
    return {
        ...state,
        [field.ANNUAL_INTEREST_RATE]: action.payload.response.data
    };
}

export default function loanReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.RESET_LOAN_REDUCER:
            return resetLoanReducer(state, action);

        case actionTypes.GET_LANDING_PAGE_SUCCESS:
            state = updateActiveLoanDetails(state, action);
            return getLoanLimitsByOrgAndStateSuccess(state, action);

        case actionTypes.GET_LOAN_LIMITS_SUCCESS:
            return getLoanLimitsSuccess(state, action);

        case actionTypes.GET_LOAN_LIMITS_BY_ORG_AND_STATE_SUCCESS:
            return getLoanLimitsByOrgAndStateSuccess(state, action);

        case actionTypes.GET_LOAN_LIMITS_BY_ORG_AND_STATE_FAILURE:
            return getLoanLimitsByOrgAndStateFailure(state, action);

        case actionTypes.RESET_STATE_IS_ACTIVE:
            return resetStateIsActive(state, action);

        case actionTypes.GET_LOAN_LIMITS_FOR_RC_CUSTOMER_SUCCESS:
            return getLoanLimitsSuccess(state, action);

        case actionTypes.GET_ACTIVE_LOAN_SUCCESS:
            return getActiveLoanSuccess(state, action);

        case actionTypes.GET_ACTIVE_LOAN_SUMMARY_SUCCESS:
            return getActiveLoanSummarySuccess(state, action);

        case actionTypes.GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY_SUCCESS:
            return getLatestNonWithdrawnLoanSummarySuccess(state, action);

        case actionTypes.GET_LOAN_DOCS_BY_CUSTOMER_SUCCESS:
            return getLoanDocsByCustomerSuccess(state, action);

        case actionTypes.GET_LOAN_DOC_CONTENT_SUCCESS:
            return getLoanDocContentByDocVersionIdSuccess(state, action);

        case actionTypes.SAVE_LOAN_AGREEMENT:
            return saveLoanAgreement(state, action);

        case actionTypes.SAVE_LOAN_AGREEMENT_SUCCESS:
            return saveLoanAgreementSuccess(state, action);

        case actionTypes.UPDATE_ACTIVE_LOAN_ENTITY_ID:
            return updateActiveLoanEntityId(state, action);

        case actionTypes.UPDATE_ACTIVE_LOAN_DETAILS:
            return updateActiveLoanDetails(state, action);

        case actionTypes.INITIATE_DRAW_SUCCESS:
            state = {
                ...state,
                [field.ACTIVE_LOAN_SUMMARY]: {
                    ...state[field.ACTIVE_LOAN_SUMMARY],
                    [field.DRAW_ATTEMPT_DETAILS]: action.payload.result
                }
            };
            return state;

        case actionTypes.GET_PAYMENT_SCHEDULE_SUCCESS:
            return getPaymentScheduleSuccess(state, action);

        case actionTypes.GET_PAYMENT_INFO_SUCCESS:
            return getPaymentInfoSuccess(state, action);

        case actionTypes.GET_PAYMENT_TRANSACTION_INFO_SUCCESS:
            return getPaymentTransactionInfoSuccess(state, action);

        case actionTypes.ACCEPT_CREDIT_LIMIT_INCREASE_SUCCESS:
            return acceptCreditLimitIncreaseSuccess(state, action);

        case actionTypes.GET_TDC_REDIRECT_PAGE_SUCCESS:
            return getTDCRedirectPageSuccess(state, action);

        case actionTypes.GET_LOC_LOWER_FEE_LEVEL_SUCCESS:
            return getLocLowerFeeLevelSuccess(state, action);

        case actionTypes.GET_CUSTOMER_BANKING_INFORMATION_SUCCESS:
            return getCustomerBankingInformationSuccess(state, action);
        case actionTypes.RETRIEVE_ANNUAL_INTEREST_RATE_SUCCESS:
            return retrieveInterestRateSuccess(state, action);

        /**
         * Uncomment only for development. Please refer to dashboard/myaccount/accountstatus/index.jsx for the
         * corresponding use case for this action.
         *  */
        // case "SET_LOAN_STATUS":
        //     return {
        //         ...state,
        //         [field.ACTIVE_LOAN_SUMMARY]: {
        //             ...state[field.ACTIVE_LOAN_SUMMARY],
        //             [field.LOAN_STATUS]: action.payload
        //         }
        //     };

        default:
            return state;
    }
}
