import {mcIdentityLoadingState} from "../../store/reducer/McIdentityReducer";

let nds;

function getNdEndpoint(clientId) {

    const MC_IDENTITY_BASE_URL_DEV = "https://api-propel.nd.nudatasecurity.com";
    const MC_IDENTITY_BASE_URL_PROD = "https://identityinsightswidget.propelholdings.com";

    return isNonProdMCIdentityEnabled()
        ? `${MC_IDENTITY_BASE_URL_DEV}/2.2/w/${clientId}/sync/js/`
        : `${MC_IDENTITY_BASE_URL_PROD}/2.2/w/${clientId}/sync/js/`;
}

export function loadMasterCardIdentityPackage(updateIsMcIdentityPackageLoaded, clientId, type, sessionId) {
    let ndEndpoint = getNdEndpoint(clientId);

    (function(w, d, s, u, q, js, fjs, nds) {
        nds = w.ndsapi || (w.ndsapi = {});
        nds.config = {
            q: [],
            ready: function(cb) {
                this.q.push(cb);
            }
        };
        js = d.createElement(s);
        fjs = d.getElementsByTagName(s)[0];
        js.src = u;
        fjs.parentNode.insertBefore(js, fjs);
        js.onload = function() {
            nds.load(u);
        };
    })(window, document, "script", ndEndpoint);

    nds = window.ndsapi;
    nds.config.ready(function() {
        nds.setSessionId(sessionId);
        if (isNonProdMCIdentityEnabled()) {
            console.log("Master card identity: Package is loaded successfully for " + type + ".");
        }
        updateIsMcIdentityPackageLoaded(mcIdentityLoadingState.LOADED);
    });
}

// Function for beginning/resuming monitoring
export function beginBehaviouralMonitoring(placement, page, sessionId, clientId) {
    // When user lands on a new placement e.g. Login, // Transfer
    if (getNdEndpoint(clientId).includes(nds.getApiBaseUrl())) {
        // This is required to debounce multiple calls
        return;
    }

    nds.setSessionId(sessionId);
    // Set placement and placement page
    nds.setPlacement(placement);
    nds.setPlacementPage(page);
    // Bind new application fields to monitor
    nds.setApiBaseUrl(undefined);
    nds.load(getNdEndpoint(clientId));
    nds.bindNewFields();

    if (isNonProdMCIdentityEnabled()) {
        console.log("Master card identity: Session id used for " + placement + " is: " + sessionId);
        console.log("Master card identity: " + placement + " behavioural monitoring started.");
    }
}

export function bindNewFormFields() {
    setTimeout(() => {
        if (nds){
            nds.bindNewFields();

        }
    }, 1000);
}

export function stopBehaviouralMonitoring(placement) {
    // Stop monitoring
    nds.stop();
    if (isNonProdMCIdentityEnabled()) {
        console.log("Master card identity: " + placement + " behavioural monitoring stopped.");
    }
}

// Function to clear previously collected behavioural data
export function clearBehaviouralData() {
    // Clear behavioural data
    nds.clear();
    if (isNonProdMCIdentityEnabled()) {
        console.log("Master card identity: cleared existing data.");
    }
}

export function getMcInsightsData() {
    const element = document.getElementsByName("nds-pmd");
    return element[0]?.value;
}

export function getMcInsightsDataAndClear(placement, stopMonitoring) {
    const data = getMcInsightsData();
    if (stopMonitoring) {
        stopBehaviouralMonitoring(placement);
    }
    clearBehaviouralData();
    return data;
}

function isNonProdMCIdentityEnabled() {
    const NODE_ENV = process.env.NODE_ENV;
    const url = window.location.origin;
    return NODE_ENV !== "production" || url.includes("qa") || url.includes("ci") || url.includes("dev");
}
