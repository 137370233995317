import * as actionTypes from "./actionTypes";

export const resetDashboardReducer = () => {
    return {
        type: actionTypes.RESET_DASHBOARD_REDUCER,
        payload: {}
    };
};

export const saveCommunicationPrefs = (id, prefs) => {
    return {
        type: actionTypes.SAVE_COMMUNICATION_PREFS,
        payload: {id, prefs}
    };
};

export const saveCommunicationPrefsSuccess = () => {
    return {
        type: actionTypes.SAVE_COMMUNICATION_PREFS_SUCCESS
    };
};

export const setCommunicationPrefs = prefs => {
    return {
        type: actionTypes.SET_COMMUNICATION_PREFS,
        payload: prefs
    };
};

export const getPersonalInformation = id => {
    return {
        type: actionTypes.GET_PERSONAL_INFO,
        payload: {id}
    };
};

export const getPersonalInformationSuccess = response => {
    return {
        type: actionTypes.GET_PERSONAL_INFO_SUCCESS,
        payload: {response}
    };
};

export const getStatements = loanId => {
    return {
        type: actionTypes.GET_STATEMENTS,
        payload: {loanId}
    };
};

export const getStatementsSuccess = response => {
    return {
        type: actionTypes.GET_STATEMENTS_SUCCESS,
        payload: {response}
    };
};

export const getStatementDetails = (loanId, statementId) => {
    return {
        type: actionTypes.GET_STATEMENT_DETAILS,
        payload: {loanId, statementId}
    };
};

export const getStatementDetailsSuccess = response => {
    return {
        type: actionTypes.GET_STATEMENT_DETAILS_SUCCESS,
        payload: {response}
    };
};

export const getStatementPDF = (loanId, statementId, date) => {
    return{
        type: actionTypes.GET_STATEMENT_PDF,
        payload: {loanId, statementId, date}
    }
}

export const getLoanAgreementErrorPopUp = () => {
    return {
        type: actionTypes.GET_LOAN_AGREEMENT_ERROR_POP_UP_MESSAGE
    }
}

export const getHistory = () => {
    return {
        type: actionTypes.GET_HISTORY
    };
};

export const getHistorySuccess = response => {
    return {
        type: actionTypes.GET_HISTORY_SUCCESS,
        payload: {response}
    };
};

export const getSignedAgreements = (userId, baseLoanId) => {
    return {
        type: actionTypes.GET_SIGNED_AGREEMENTS,
        payload: {userId, baseLoanId}
    };
};

export const getSignedAgreementsSuccess = response => {
    return {
        type: actionTypes.GET_SIGNED_AGREEMENTS_SUCCESS,
        payload: {response}
    };
};

export const getAgreementDetails = (id, loanId, docVersionId, docStateId) => {
    return {
        type: actionTypes.GET_AGREEMENT_DETAILS,
        payload: {id, loanId, docVersionId, docStateId}
    };
};

export const getAgreementDetailsSuccess = response => {
    return {
        type: actionTypes.GET_AGREEMENT_DETAILS_SUCCESS,
        payload: {response}
    };
};

export const resetAgreementDetails = () => {
    return {
        type: actionTypes.RESET_AGREEMENT_DETAILS,
        payload: {}
    };
};

export const getActionItems = loanId => {
    return {
        type: actionTypes.GET_ACTION_ITEMS,
        payload: {loanId}
    };
};

export const getActionItemsSuccess = result => {
    return {
        type: actionTypes.GET_ACTION_ITEMS_SUCCESS,
        payload: {result}
    };
};
export const setTagsForActionItemsSuccess = result => {
    return {
        type: actionTypes.SAVE_TAGS_ON_ACTION_ITEMS_SUCCESS,
        payload: {result}
    };
};

export const getBankVerificationItems = () => {
    return {
        type: actionTypes.GET_BANK_VERIFICATION_ITEMS,
        payload: null
    };
};

export const getBankVerificationItemsSuccess = result => {
    return {
        type: actionTypes.GET_BANK_VERIFICATION_ITEMS_SUCCESS,
        payload: {result}
    };
};

export const getBankVerificationConfirmed = loanId => {
    return {
        type: actionTypes.GET_BANK_VERIFICATION_CONFIRMED,
        payload: {loanId}
    };
};

export const getBankVerificationConfirmedSuccess = response => {
    return {
        type: actionTypes.GET_BANK_VERIFICATION_CONFIRMED_SUCCESS,
        payload: {response}
    };
};

export const getAchDetails = () => {
    return {
        type: actionTypes.GET_ACH_DETAILS,
        payload: {}
    };
};

export const getAchDetailsSuccess = response => {
    return {
        type: actionTypes.GET_ACH_DETAILS_SUCCESS,
        payload: {response}
    };
};

export const reexpress = (loanId, nextPage) => {
    return {
        type: actionTypes.REEXPRESS,
        payload: {loanId, nextPage}
    };
};

export const updateCustomerInfoAndReExpress = (customerId, loanId) => {
    return {
        type: actionTypes.UPDATE_CUSTOMER_INFO_AND_REEXPRESS,
        payload: {customerId, loanId}
    };
};

export const verifyEffectiveDate = nextPage => {
    return {
        type: actionTypes.VERIFY_EFFECTIVE_DATE,
        payload: {nextPage}
    };
};

export const initiateDraw = (baseLoanId, customerId, drawAmount, subIdTrackingId) => {
    return {
        type: actionTypes.INITIATE_DRAW,
        payload: {baseLoanId, customerId, drawAmount, subIdTrackingId}
    };
};

export const initiateDrawSuccess = result => {
    return {
        type: actionTypes.INITIATE_DRAW_SUCCESS,
        payload: {result}
    };
};

export const getRepplyApplicationData = (isFromAccountPage) => {
    return {
        type: actionTypes.GET_REAPPLY_APPLICATION_DATA,
        payload: {isFromAccountPage}
    };
};

export const isCustomerTXEpicImport = (customerId, baseLoanId) => {
    return {
        type: actionTypes.IS_CUSTOMER_TX_EPIC_IMPORT,
        payload: {customerId, baseLoanId}
    };
};

export const isCustomerTXEpicImportSuccess = result => {
    return {
        type: actionTypes.IS_CUSTOMER_TX_EPIC_IMPORT_SUCCESS,
        payload: {result}
    };
};

export const clearSelectedStatementContent = () => {
    return {
        type: actionTypes.CLEAR_SELECTED_STATEMENT
    };
};

export const clearStatements = () => {
    return {
        type: actionTypes.CLEAR_STATEMENTS
    };
};

export const setOfferLinkFlag = () => {
    return {
        type: actionTypes.SET_OFFER_LINK_FLAG,
        payload: {
            data: false
        }
    };
};

export const updateContactInfo = (contactNumber, isMobile, receiveOffers) => {
    return {
        type: actionTypes.UPDATE_CONTACT_INFO,
        payload: {
            contactNumber,
            isMobile,
            receiveOffers
        }
    };
};

export const updateCommPreference = data => {
    return {
        type: actionTypes.UPDATE_COMM_PREFERENCE,
        payload: {
            data
        }
    };
};

export const saveOnlinePaymentDetails = data => {
    return {
        type: actionTypes.SAVE_ONLINE_PAYMENT_DETAILS,
        payload: {
            data
        }
    };
};

export const isValidDebitCard = (form, field, debitCard) => {
    return {
        type: actionTypes.CHECK_IS_DEBIT_CARD,
        payload: {
            form,
            field,
            debitCard
        }
    };
};

export const isCardPaymentEnabledSuccess = data => {
    return {
        type: actionTypes.PAYMENT_CARD_ENABLED_SUCCESS,
        payload: {
            data
        }
    };
};

export const isCardPaymentEnabled = data => {
    return {
        type: actionTypes.PAYMENT_CARD_ENABLED,
        payload: {
            state: data
        }
    };
};

export const getStatementBalance = () => {
    return {
        type: actionTypes.GET_STATEMENT_BALANCE,
        payload: {}
    };
};

export const getStatementBalanceSuccess = data => {
    return {
        type: actionTypes.GET_STATEMENT_BALANCE_SUCCESS,
        payload: {
            data
        }
    };
};

export const getCardPaymentPreview = () => {
    return {
        type: actionTypes.GET_CARD_PAYMENT_PREVIEW,
        payload: {}
    };
};

export const getCardPaymentPreviewSuccess = data => {
    return {
        type: actionTypes.GET_CARD_PAYMENT_PREVIEW_SUCCESS,
        payload: {
            data
        }
    };
};

export const submitCardPayment = data => {
    return {
        type: actionTypes.SUBMIT_CARD_PAYMENT,
        payload: {
            data
        }
    };
};

export const getPaymentUrl = (loanId, amount) => {
    return {
        type: actionTypes.GET_PAYMENT_URL,
        payload: {
            amount,
            loanId,
        }
    };
}

export const getPaymentUrlSuccess = data => {
    return {
        type: actionTypes.GET_PAYMENT_URL_SUCCESS,
        payload: {
            data
        }
    };
};

export const submitCardPaymentSuccess = data => {
    return {
        type: actionTypes.SUBMIT_CARD_PAYMENT_SUCCESS,
        payload: {
            data
        }
    };
};

export const resetPaymentUrl = () => {
    return {
        type: actionTypes.RESET_PAYMENT_URL,
        payload: {}
    };
};

export function trackCardPaymentPreview(data, isLppSupported) {
    return {
        type: actionTypes.TRACK_CARD_PAYMENT_PREVIEW,
        payload: {
            data,
            isLppSupported
        }
    }
}

export const setShowLoader = () => {
    return {
        type: actionTypes.SET_SHOW_LOADER,
        payload: {}
    };
};

export const unsetShowLoader = () => {
    return {
        type: actionTypes.UNSET_SHOW_LOADER,
        payload: {}
    };
};

export const setShowError = () => {
    return {
        type: actionTypes.SET_SHOW_ERROR,
        payload: {}
    };
};

export const unsetShowError = () => {
    return {
        type: actionTypes.UNSET_SHOW_ERROR,
        payload: {}
    };
};

// TDC Actions
export const getTDCPersonalInformation = id => {
    return {
        type: actionTypes.GET_TDC_PERSONAL_INFO,
        payload: {id}
    };
};

export const getTDCPersonalInformationSuccess = response => {
    return {
        type: actionTypes.GET_TDC_PERSONAL_INFO_SUCCESS,
        payload: {response}
    };
};

export const saveSpousalInfo = data => {
    return {
        type: actionTypes.SAVE_SPOUSAL_INFO,
        payload: {
            data
        }
    };
};

export const saveSpousalInfoSuccess = response => {
    return {
        type: actionTypes.SAVE_SPOUSAL_INFO_SUCCESS,
        payload: {response}
    };
};

export const getTDCRedirectPage = id => {
    return {
        type: actionTypes.GET_TDC_REDIRECT_PAGE,
        payload: {id}
    };
};

export const getTDCRedirectPageSuccess = response => {
    return {
        type: actionTypes.GET_TDC_REDIRECT_PAGE_SUCCESS,
        payload: {response}
    };
};

export const submitForAutoApproval = (baseLoanId, customerId) => {
    return {
        type: actionTypes.SUBMIT_AUTO_APPROVAL,
        payload: {baseLoanId, customerId}
    };
};

export const submitForAutoApprovalSuccess = result => {
    return {
        type: actionTypes.SUBMIT_AUTO_APPROVAL_SUCCESS,
        payload: {result}
    };
};

export const otpGetStatus = customerId => {
    return {
        type: actionTypes.OTP_GET_STATUS,
        payload: {
            customerId
        }
    };
};

export const otpGetStatusSuccess = response => {
    return {
        type: actionTypes.OTP_GET_STATUS_SUCCESS,
        payload: {
            response
        }
    };
};

export const otpSendCode = (customerId, isSendCodeAllowed) => {
    return {
        type: actionTypes.OTP_SEND_CODE,
        payload: {
            customerId,
            isSendCodeAllowed
        }
    };
};

export const otpSendCodeSuccess = response => {
    return {
        type: actionTypes.OTP_SEND_CODE_SUCCESS,
        payload: {
            response
        }
    };
};

export const otpVerifyCode = (customerId, verificationCode, form, field) => {
    return {
        type: actionTypes.OTP_VERIFY_CODE,
        payload: {
            customerId,
            verificationCode,
            form,
            field
        }
    };
};

export const otpVerifyCodeSuccess = response => {
    return {
        type: actionTypes.OTP_VERIFY_CODE_SUCCESS,
        payload: {
            response
        }
    };
};

export const processOTPData = (baseLoanId, lead_sequence_id, type, phoneNumber, email, organizationId) => {
    return {
        type: actionTypes.OTP_PROCESS_DATA,
        payload: {
            baseLoanId,
            lead_sequence_id,
            type,
            phoneNumber,
            email,
            organizationId
        }
    };
};

export const processOTPDataSuccess = response => {
    return {
        type: actionTypes.OTP_PROCESS_DATA_SUCCESS,
        payload: {
            response
        }
    };
};

export const processPayrollData = (baseLoanId, lead_sequence_id, organizationId) => {
    return {
        type: actionTypes.PAYROLL_PROCESS_DATA,
        payload: {
            baseLoanId,
            lead_sequence_id,
            organizationId
        }
    };
};

export const processPayrollDataSuccess = response => {
    return {
        type: actionTypes.PAYROLL_PROCESS_DATA_SUCCESS,
        payload: {
            response
        }
    };
};

export const checkDrawEligibility = baseLoanId => {
    return {
        type: actionTypes.CHECK_DRAW_ELIGIBILITY,
        payload: {
            baseLoanId
        }
    };
};

export const checkDrawEligibilitySuccess = response => {
    return {
        type: actionTypes.CHECK_DRAW_ELIGIBILITY_SUCCESS,
        payload: {
            response
        }
    };
};

export const showLocLowerFee = baseLoanId => {
    return {
        type: actionTypes.SHOW_LOC_LOWER_FEE,
        payload: {
            baseLoanId
        }
    };
};

export const showLocLowerFeeSuccess = response => {
    return {
        type: actionTypes.SHOW_LOC_LOWER_FEE_SUCCESS,
        payload: {
            response
        }
    };
};

export const saveLocLowerFeePopupAcknowledgement = baseLoanId => {
    return {
        type: actionTypes.SAVE_LOC_LOWER_FEE_ACKNOWLEDGEMENT,
        payload: {
            baseLoanId
        }
    };
};

export const saveLocLowerFeePopupAcknowledgementSuccess = response => {
    return {
        type: actionTypes.SAVE_LOC_LOWER_FEE_ACKNOWLEDGEMENT_SUCCESS,
        payload: {
            response
        }
    };
};

export const setDocumentViewed = (userId, baseLoanId, documentVersionId) => {
    return {
        type: actionTypes.SAVE_DOCUMENT_VIEWED_ON_DASHBOARD,
        payload: {
            userId,
            baseLoanId,
            documentVersionId
        }
    };
};

export const setDocumentViewedSuccess = response => {
    return {
        type: actionTypes.SAVE_DOCUMENT_VIEWED_ON_DASHBOARD_SUCCESS,
        payload: {
            response
        }
    };
};

export const documentUpload = (data, baseLoanId) => {
    return {
        type: actionTypes.UPLOAD_DOCUMENT,
        payload: {
            data: data,
            baseLoanId: baseLoanId
        }
    };
};

export const documentUploadSuccess = result => {
    return {
        type: actionTypes.UPLOAD_DOCUMENT_SUCCESS,
        payload: {result}
    };
};

export const setFlinksLoginStarted = data => ({
    type: actionTypes.SET_FLINKS_LOGIN_STARTED,
    payload: data
});

export const setFlinksLoginOAuth = data => ({
    type: actionTypes.SET_FLINKS_LOGIN_OAUTH,
    payload: data
});

export const setFlinksLoginSuccess = data => ({
    type: actionTypes.SET_FLINKS_LOGIN_SUCCESS,
    payload: data
});

export const setFlinksLoginFailure = data => ({
    type: actionTypes.SET_FLINKS_LOGIN_FAILURE,
    payload: data
});

export const setFlinksMfaError = data => ({
    type: actionTypes.SET_FLINKS_LOGIN_FAILURE,
    payload: data
});

export const saveRequestDetails = data => ({
    type: actionTypes.SAVE_REQUEST_DETAILS,
    payload: data
});

export const saveRequestDetailsSuccess = data => ({
    type: actionTypes.SAVE_REQUEST_DETAILS_SUCCESS,
    payload: data
});

export const saveRequestDetailsFailure = data => ({
    type: actionTypes.SAVE_REQUEST_DETAILS_FAILURE,
    payload: data
});

export const isDocumentUploadEnabled = data => {
    return {
        type: actionTypes.DOCUMENT_UPLOAD_ENABLED,
        payload: {
            state: data
        }
    };
};

export const isDocumentUploadEnabledSuccess = data => {
    return {
        type: actionTypes.DOCUMENT_UPLOAD_ENABLED_SUCCESS,
        payload: {
            data
        }
    };
};

export const resetBvDataCheck = () => {
    return {
        type: actionTypes.RESET_BV_CHECK,
        payload: null
    };
};

export const checkBVDataReady = (data) => {
    return {
        type: actionTypes.CHECK_BV_DATA_READY,
        payload: data
    };
};

export const checkBVDataReadySuccess = result => {
    return {
        type: actionTypes.CHECK_BV_DATA_READY_SUCCESS,
        payload: {result}
    };
};

export const checkBVDataReadyFailure = () => {
    return {
        type: actionTypes.CHECK_BV_DATA_READY_FAILURE,
        payload: null
    };
};

export const getPollParameters = (data) => {
    return {
        type: actionTypes.GET_POLL_PARAMS,
        payload: data
    };
};

export const getPollParametersSuccess = result => {
    return {
        type: actionTypes.GET_POLL_PARAMS_SUCCESS,
        payload: {result}
    };
};

export const getPollParametersFailure = () => {
    return {
        type: actionTypes.GET_POLL_PARAMS_FAILURE,
        payload: null
    };
};

export const checkBVConnectionStatus = (data) => {
    return {
        type: actionTypes.CHECK_BV_CONN_STATUS,
        payload: data
    };
};

export const isAccountOnHold = id => {
    return {
        type: actionTypes.IS_ACCOUNT_ON_HOLD,
        payload: {id}
    };
};
export const isAccountOnHoldSuccess = data => {
    return {
        type: actionTypes.IS_ACCOUNT_ON_HOLD_SUCCESS,
        payload: {data}
    };
};

export const createOnfidoSdk = (customerId, loanId) => {
    return {
        type: actionTypes.CREATE_ONFIDO_SDK,
        payload: {customerId, loanId}
    }
}

export const createOnfidoSdkSuccess = data => {
    return {
        type: actionTypes.CREATE_ONFIDO_SDK_SUCCESS,
        payload: data
    }
}

export const onfidoSdkEventCallback = data => {
    return {
        type: actionTypes.ONFIDO_SDK_EVENT,
        payload: data
    }
}
export const onfidoSdkEventCallbackSuccess = data => {
    return {
        type: actionTypes.ONFIDO_SDK_EVENT_SUCCESS,
        payload: data
    }
}

export const onfidoSdkEventTracking = data => {
    return {
        type: actionTypes.ONFIDO_SDK_EVENT_TRACKING,
        payload: data
    }
}
export const onfidoSdkEventTrackingSuccess = data => {
    return {
        type: actionTypes.ONFIDO_SDK_EVENT_TRACKING_SUCCESS,
        payload: data
    }
}
export const cancelDraw = (baseLoanId,customerId) => {
    return {
        type: actionTypes.CANCEL_DRAW,
        payload: {baseLoanId,customerId}
    };
};

export const cancelDrawSuccess = result => {
    return {
        type: actionTypes.CANCEL_DRAW_SUCCESS,
        payload: {result}
    };
};

export const getCreditEvolutionStatus = (loanId) => {
    return {
        type: actionTypes.CREDIT_EVOLUTION_STATUS,
        payload: {
            loanId: loanId
        }
    };
};

export const getCreditEvolutionStatusSuccess = response => {
    return {
        type: actionTypes.CREDIT_EVOLUTION_STATUS_SUCCESS,
        payload: {
            response
        }
    };
};

export const getPricingModelDetails = (loanId) => {
    return {
        type: actionTypes.PRICING_MODEL_DETAILS,
        payload: {
            loanId: loanId
        }
    };
};

export const getPricingModelDetailsSuccess = response => {
    return {
        type: actionTypes.PRICING_MODEL_DETAILS_SUCCESS,
        payload: {
            response
        }
    };
};

export const getCreditEvolutionDetail = (loanId) => {
    return {
        type: actionTypes.CREDIT_EVOLUTION_DETAIL,
        payload: {
            loanId: loanId
        }
    };
};

export const getCreditEvolutionDetailSuccess = response => {
    return {
        type: actionTypes.CREDIT_EVOLUTION_DETAIL_SUCCESS,
        payload: {
            response
        }
    };
};

export const getAchInfo = (loanId) => {
    return {
        type: actionTypes.GET_ACH_INFO,
        payload: {
            loanId: loanId
        }
    };
};

export const getAchInfoSuccess = response => {
    return {
        type: actionTypes.GET_ACH_INFO_SUCCESS,
        payload: {
            response
        }
    };
};

export const getFilledDocument = (loanId, docGroup) => {
    return {
        type: actionTypes.GET_FILLED_DOCUMENT,
        payload: {
            loanId: loanId,
            docGroup: docGroup
        }
    };
};

export const getFilledDocumentSuccess = response => {
    return {
        type: actionTypes.GET_FILLED_DOCUMENT_SUCCESS,
        payload: {
            response
        }
    };
};

export const submitSignedDocument = (data) => {
    return {
        type: actionTypes.SUBMIT_SIGNED_DOCUMENT,
        payload: data
    };
};

export const submitSignedDocumentSuccess = response => {
    return {
        type: actionTypes.SUBMIT_SIGNED_DOCUMENT_SUCCESS,
        payload: {
            response
        }
    };
};

export const getLppPaymentUrl = (loanId, amount) => {
    return {
        type: actionTypes.GET_LPP_PAYMENT_URL,
        payload: {
            amount,
            loanId,
        }
    };
}
export const getLppPaymentUrlSuccess = data => {
    return {
        type: actionTypes.GET_LPP_PAYMENT_URL_SUCCESS,
        payload: {
            data
        }
    };
};

export const getOriginatedUrlInfo = loanId => {
    return {
        type: actionTypes.GET_ORIGINATED_URL_INFO,
        payload: {loanId}
    };
};

export const getOriginatedUrlInfoSuccess = response => {
    return {
        type: actionTypes.GET_ORIGINATED_URL_INFO_SUCCESS,
        payload: {response}
    };
};

export const settlementPopupClosed = () => {
    return {
        type: actionTypes.SETTLEMENT_POPUP_CLOSED,
        payload: {}
    };
};


export const scheduleAchPayment = (data) => {
    return {
        type: actionTypes.SCHEDULE_ACH_PAYMENT,
        payload: data
    };
};

export const scheduleAchPaymentSuccess = data => {
    return {
        type: actionTypes.SCHEDULE_ACH_PAYMENT_SUCCESS,
        payload: data
    };
};
