import _ from "lodash";

import * as actionTypes from "../action/actionTypes";
import * as field from "../../shared/constant/field";
import * as routeutility from "../../shared/utility/routeutility";
import * as localStorageUtility from "../../shared/utility/localstorageutility";
import {isForaCredit} from "../../shared/utility/utility";
import * as constant from "../../shared/constant/constant";
import moment from "moment";
import Cookies from "js-cookie";

const getSubIdTrackingInitialState = () => {
    return {
        [field.ID]: null,
        [field.SUB_ID]: null,
        [field.SUB_ID2]: null,
        [field.SUB_ID3]: null,
        [field.CLICK_ID_1]: null,
        [field.AD_ID]: null,
        [field.UTM_CAMPAIGN]: null,
        [field.TIERKEY]: null,
        [field.TIERKEY_MATCHES_ORGANIZATION]: null,
        [field.CREATE_TIMESTAMP]: null,
        [field.UPDATE_TIMESTAMP]: null,
        [field.GCLID]:null,
        [field.FBC]:null,
        [field.FBP]:null

    };
}

const initialState = {
    [field.USERAPPINFO]: {
        [field.ID]: null,
        [field.USERID]: null,
        [field.FIRSTNAME]: null,
        [field.LASTNAME]: null,
        [field.EMAIL]: null,
        [field.DOB]: null,
        [field.SSN]: null,
        [field.PHONE_NUMBER]: null,
        [field.RESIDENCY_STATUS]: null,
        [field.IS_MOBILE]: null,
        [field.ADDRESS]: null,
        [field.ADDRESS2]: null,
        [field.CITY]: null,
        [field.STATE]: null,
        [field.ZIP]: null,
        [field.IS_RENTAL]: null,
        [field.YEARS]: null,
        [field.INCOME_TYPE]: null,
        [field.PAYMENT_TYPE]: null,
        [field.EMPLOYER_NAME]: null,
        [field.ROUTING_NUMBER]: null,
        [field.BANK_NAME]: null,
        [field.ACCOUNT_NUMBER]: null,
        [field.ACCOUNT_TYPE]: null,
        [field.BANK_TRANSIT_CODE]:null,
        [field.BANK_INSTITUTION_CODE]:null,
        [field.PAY_FREQUENCY]: null,
        [field.NEXT_PAY_DATE]: null,
        [field.LAST_PAY_DATE]: null,
        [field.PAYCHECK_AMOUNT]: null,
        [field.GROSS_MONTHLY]: null,
        [field.NMI]: null,
        [field.AMOUNT]: null,
        [field.IS_RECEIVE_OFFERS]: null,
        [field.APPCODE]: null,
        [field.EXISTING_FLEX_LOAN]: null,
        [field.CHOICES]: null,
        [field.ISPRODUCTCHANGEPOPUPSHOWN]: null,
        [field.HAS_NEW_FEES]: null
    },
    [field.USERAPPMISC]: {
        [field.CUSTOMERID]: undefined,
        [field.ISRAL]: undefined,
        [field.ISRC]: false,
        [field.IS_XRAL]: false,
        [field.TIERKEY]: "",
        [field.IS_ACTIVE_ACCOUNT]: undefined,
        [field.LOANID]: undefined,
        [field.LOAN_AMOUNT]: undefined,
        [field.IS_SHOW_APPCODE]: null,
        [field.PRESTAGEDECISION]: null,
        [field.POSTSTAGEDECISION]: null,
        [field.ISKYC]: null,
        [field.ISTERMSAGREED]: null,
        [field.ISCONTACTUPDATED]: null,
        [field.IS_PROMOCODE_VALID]: null,
        [field.DM_TIERKEY_MESSAGE]: "",
        [field.PROMO_CODE]: null,
        [field.DM_PROMO_CODE_AMOUNT]: null,
        [field.CLICK_ID]: null,
        // Default value is null.
        // If user come on welcome page, then this will set to false through getExternalCustomerAppInfoSuccess method in ApplicationReducer.
        // If user is coming via organic flow , it is set to true after the state info page success
        [field.IS_ORGANIC]: null,
        [field.IS_SHORTFORM]: null,
        [field.DUPLICATE_ORGANIZATION]: null,
        [field.ISPASSWORDTEMPORARY]: true,
        [field.APPLY_LINK_CLICKED]: false,
        [field.PROMO_CODE_ORG_ID]: null,
        [field.OFFER_PAGE_VALIDATED]: false,
        [field.DM_CALCULATION_MODEL]: null,
        [field.IS_CREDITFRESH_SUPPORTED]: null,
        [field.CREDITFRESH_LIMIT]: null,
        [field.ORGANIZATION.ORGANIZATION_ID]: null,
        [field.IS_PHONE_VERIFIED]: null,
        [field.IS_PHONE_VERIFICATION_SKIPPED]: null,
        [field.PHONE_VERIFICATION_HAS_MOBILE]: null,
        [field.CONTACT_INFO_IS_MOBILE]: null,
        [field.PHONE_VERIFICATION_STATUS_MESSAGE]: null,
        [field.PHONE_VERIFICATION_SUBMITTED]: null,
        [field.INITIAL_MFA_VERIFICATION_SENT]: false,
        [field.MFA_PASSWORD_VERIFICATION_STATUS]: false,
        [field.MFA_VERIFICATION_STATUS]: false,
        [field.USER_MFA_ENABLED]: false,
        [field.ORG_MFA_ENABLED]: null,
        [field.SHOW_MFA_NOTIFICATION]: null,
        [field.MFA_PASSWORD_ATTEMPT]: 0,
        [field.IS_RC_LOGIN_FOR_MFA]: false,
        [field.MFA_CODE_SUBMIT_RESPONSE]: null,
        [field.OTP_LOGIN_SEND_COUNT]: 0,
        [field.OTP_LOGIN_ATTEMPT_COUNT]: 0,
        [field.INITIAL_OTP_LOGIN_VERIFICATION_SENT]: false,
        [field.MFA_VERIFICATION_CONTACT_TYPE]: null,
        [field.MFA_SMS_SERVICE_DOWN]: false,
        [field.EXTERNAL_REFERENCE_ID]: null,
        [field.HB_PRE_AND_POST_STAGE_FAIL]:null
    },
    [field.USERLOANAPPINFO]: {
        [field.ISPHONEVERIFICATIONPAGESUBMITTED]: false,
        [field.ISEMPLOYMENTINFOPAGESUBMITTED]: false,
        [field.ISPAYMENTSCHEDULEPAGESUBMITTED]: false,
        [field.ISBANKINGINFOPAGESUBMITTED]: false,
        [field.ISPREAPPROVEDPAGESUBMITTED]: false,
        [field.USERREQUESTEDAMOUNT]: null,
        [field.ORIGINATEDUNIQUEURLVISITED]: null
    },
    [field.USER_LOGIN_INFO]: {
        [field.ASSOCIATED_ORG]: null,
        [field.UNIQUE]: null
    },
    [field.SUB_ID_TRACKING]: getSubIdTrackingInitialState(),
    [field.KYC]: {
        [field.KYC_QUESTIONS]: [
            /*
            Sample Instance
            {
                [field.KYC_ID]: null,
                [field.KYC_HEADER_ID]: null,
                [field.KYC_QUESTION_ID]: null,
                [field.KYC_QUESTION_ORDER]: null,
                [field.KYC_QUESTION_TYPE]: null,
                [field.KYC_DISPLAY]: null,
                [field.KYC_ANSWER_LIST]: [
                    {
                        [field.KYC_ANSWER_ID]: null,
                        [field.KYC_ANSWER_QUESTION_ID]: null,
                        [field.KYC_ANSWER_CHOICE_ID]: null,
                        [field.KYC_ANSWER_CHOICE_ORDER]: null,
                        [field.KYC_DISPLAY]: null,
                    }
                ]
            }
            */
        ],
        [field.KYC_REMAINING_SECONDS]: null,
        [field.KYC_SUBMISSION_RESPONSE]: null,
        [field.KYC_REAPPLY_TIME]: null,
        [field.IS_KYC_COMPLETED]: null
    },
    [field.LOAN_LIMITS]: {
        [field.LOAN_LIMITS__MIN_AMOUNT]: null,
        [field.LOAN_LIMITS__MAX_AMOUNT]: null
    },
    [field.OPTOUT]: {
        [field.OPTOUT_ID]: null,
        [field.OPTOUT_CUSTOMER_ID]: null,
        [field.OPTOUT_BASE_LOAN_ID]: null,
        [field.OPTOUT_AFFILIATE_BUSINESS_PURPOSE]: null,
        [field.OPTOUT_AFFILIATE_MARKETING]: null,
        [field.OPTOUT_NONAFFILIATE_MARKETING]: null,
        [field.OPTOUT_CREATE_TIMESTAMP]: null
    }
};

const _updateUserAppInfo = (state, data) => {
    return {
        ...state,
        ...{
            [field.USERAPPINFO]: {...state[field.USERAPPINFO], ...data}
        }
    };
};

const _updateUserLoginInfo = (state, data) => {
    return {
        ...state,
        ...{
            [field.USER_LOGIN_INFO]: {...state[field.USER_LOGIN_INFO], ...data}
        }
    };
};

const _updateUserAppMisc = (state, data) => {
    return {
        ...state,
        ...{
            [field.USERAPPMISC]: {...state[field.USERAPPMISC], ...data}
        }
    };
};

const _updateUserSubIdTracking = (state, data) => {
    let draftState = _.cloneDeep(state);
    draftState[field.SUB_ID_TRACKING] = data;
    if(!_.isEmpty(data.tierKey) && data.tierKeyMatchesOrganization === true){
        draftState[field.USERAPPMISC][field.TIERKEY] = data.tierKey;
    }
    return draftState;
};

const _updateUserLoanAppProgress = (state, data) => {
    return {
        ...state,
        ...{
            [field.USERLOANAPPINFO]: {...state[field.USERLOANAPPINFO], ...data}
        }
    };
};

const _updateAuthInfo = (state, data) => {
    return {
        ...state,
        ...{
            [field.AUTH]: {...state[field.AUTH], ...data}
        }
    };
};

const _updateLoanLimits = (state, data) => {
    return {
        ...state,
        ...{
            [field.LOAN_LIMITS]: {...state[field.LOAN_LIMITS], ...data}
        }
    };
};
const _getFBCAndFBPParameters = () => {
     let fbp = Cookies.get("_fbp");
     let fbc = Cookies.get("_fbc");
     if (fbc == null) {
         const queryParameters = new URLSearchParams(window.location.search)
         fbc = queryParameters.get("fbclid");
     }
     return {fbp, fbc};
}
const _saveUrlparams = (state, data) => {
    let draftState = _.cloneDeep(state);

    if (_.isEmpty(state[field.USERAPPMISC][field.CLICK_ID]) && data.subid) {
        let splitArr = data.subid.split("-");
        draftState[field.USERAPPMISC][field.CLICK_ID] = splitArr[1];
    }

    if (_.isEmpty(state[field.USERAPPMISC][field.TIERKEY]) && data.defaultTierkey) {
        draftState[field.USERAPPMISC][field.TIERKEY] = data.defaultTierkey;
    }

    if (data.isXRal) {
        draftState[field.USERAPPMISC][field.IS_XRAL] = data.isXRal === "true";
    }

    if (_.isEmpty(state[field.USERAPPINFO][field.EMAIL]) && data.email) {
        draftState[field.USERAPPINFO][field.EMAIL] = decodeURIComponent(data.email);
    }

    if (_.isEmpty(state[field.USERAPPINFO][field.STATE]) && data.state) {
        draftState[field.USERAPPINFO][field.STATE] = data.state;
    }

    if (_.isEmpty(state[field.USERAPPINFO][field.AMOUNT]) && data.requested_amount) {
        //read the amount for Organic apps
        draftState[field.USERAPPINFO][field.AMOUNT] = parseInt(data.requested_amount, 10);
    } else if (_.isEmpty(state[field.USERAPPINFO][field.AMOUNT]) && data.loanAmount) {
        //read the amount for DM apps
        draftState[field.USERAPPINFO][field.AMOUNT] = parseInt(data.loanAmount, 10);
    }

    if (data.state) {
        draftState[field.USERAPPINFO][field.STATE] = data.state;
    }

    if (data.overrideTierKey) {
        draftState[field.USERAPPMISC][field.OVERRIDE_TIERKEY] = data.overrideTierKey;
    }

    if (_.isEmpty(state[field.USERAPPINFO][field.APPCODE]) && data.application_code) {
        draftState[field.USERAPPINFO][field.APPCODE] = data.application_code;
    }

    if (_.isEmpty(state[field.USERAPPINFO][field.FIRSTNAME]) && data.first_name) {
        draftState[field.USERAPPINFO][field.FIRSTNAME] = data.first_name;
    }

    if (_.isEmpty(state[field.USERAPPINFO][field.LASTNAME]) && data.last_name) {
        draftState[field.USERAPPINFO][field.LASTNAME] = data.last_name;
    }

    if (_.isEmpty(state[field.USERAPPINFO][field.ZIP]) && data.zipcode) {
        draftState[field.USERAPPINFO][field.ZIP] = data.zipcode;
    }

    if (_.isEmpty(state[field.USERAPPINFO][field.CITY]) && data.city) {
        draftState[field.USERAPPINFO][field.CITY] = data.city;
    }

    if (_.isEmpty(state[field.USERAPPINFO][field.ADDRESS]) && data.address) {
        draftState[field.USERAPPINFO][field.ADDRESS] = decodeURIComponent(data.address);
    }
    let {fbp, fbc} =(data.cq_src===constant.FACEBOOK_ADS)? _getFBCAndFBPParameters():"";
    let currentTime = moment.utc().format("X");
    let addToLocalStorage = true;
    if(localStorageUtility.getLcalStorageValue('sub_id_landing_time')){
        let thresholdHours = data.subIdTrackingThreshold || 24;

        let landingTime = localStorageUtility.getLcalStorageValue('sub_id_landing_time');
        let expiryTime = parseInt(landingTime) + (3600 * thresholdHours);
        if(currentTime < expiryTime){
            addToLocalStorage = false;
        } else {
            localStorageUtility.removeLSValue('sub_id_landing_time');
            localStorageUtility.removeLSValue('subId1');
            localStorageUtility.removeLSValue('subId2');
            localStorageUtility.removeLSValue('subId3');
            localStorageUtility.removeLSValue('clickId');
            localStorageUtility.removeLSValue('adId');
            localStorageUtility.removeLSValue('utmCampaign');
            localStorageUtility.removeLSValue('amid');
            localStorageUtility.removeLSValue('atrv');
            localStorageUtility.removeLSValue('ald');
            localStorageUtility.removeLSValue('tierkey');
            localStorageUtility.removeLSValue('gclId');
            localStorageUtility.removeLSValue('fbc');
            localStorageUtility.removeLSValue('fbp');
            localStorageUtility.removeLSValue('cqSrc');
        }
    }
    if(data.tierkey && (data.overrideTierKey || localStorageUtility.getLcalStorageValue('tierkey'))){


        if(addToLocalStorage){
            localStorageUtility.setLocalStorageValue('sub_id_landing_time', currentTime);
            localStorageUtility.setLocalStorageValue('subId1', data.subid || "");
            localStorageUtility.setLocalStorageValue('subId2', data.subid2 || "");
            localStorageUtility.setLocalStorageValue('subId3', data.subid3 || "");
            localStorageUtility.setLocalStorageValue('clickId', data.clickid || "");
            localStorageUtility.setLocalStorageValue('adId', data.adid || "");
            localStorageUtility.setLocalStorageValue('utmCampaign', data.utm_campaign || "");
            localStorageUtility.setLocalStorageValue('amid', data.ranMID || "");
            localStorageUtility.setLocalStorageValue('atrv', data.ranSiteID || "");
            localStorageUtility.setLocalStorageValue('ald', moment.utc().format("YYYYMMDD_HHmm"));
            localStorageUtility.setLocalStorageValue('tierkey', data.tierkey);
            localStorageUtility.setLocalStorageValue('cq_src', data.cq_src||"");
            localStorageUtility.setLocalStorageValue('gclId', data.gclid || "");
            localStorageUtility.setLocalStorageValue('fbc', fbc || "");
            localStorageUtility.setLocalStorageValue('fbp', fbp || "")
        }
        draftState[field.SUB_ID_TRACKING][field.ID] = null;
        draftState[field.SUB_ID_TRACKING][field.SUB_ID] = data.subid;
        draftState[field.SUB_ID_TRACKING][field.SUB_ID2] = data.subid2;
        draftState[field.SUB_ID_TRACKING][field.SUB_ID3] = data.subid3;
        draftState[field.SUB_ID_TRACKING][field.CLICK_ID_1] = data.clickid;
        draftState[field.SUB_ID_TRACKING][field.AD_ID] = data.adid;
        draftState[field.SUB_ID_TRACKING][field.UTM_CAMPAIGN] = data.utm_campaign;
        draftState[field.SUB_ID_TRACKING][field.MERCHANT_ID] = data.ranMID;
        draftState[field.SUB_ID_TRACKING][field.RAN_SITE_ID] = data.ranSiteID;
        draftState[field.SUB_ID_TRACKING][field.SUB_ID_LANDING_TIME] = moment.utc().format("YYYYMMDD_HHmm");
        draftState[field.SUB_ID_TRACKING][field.TIERKEY] = data.tierkey;
        draftState[field.SUB_ID_TRACKING][field.GCLID] = data.gclid;
        draftState[field.SUB_ID_TRACKING][field.FBP] = fbp;
        draftState[field.SUB_ID_TRACKING][field.FBC] = fbc;
        draftState[field.SUB_ID_TRACKING][field.CQ_SRC] = data.cq_src;
    }

    return draftState;
};

const _updateUserOptOutPreferences = (state, data) => {
    return {
        ...state,
        ...{
            [field.OPTOUT]: {...state[field.OPTOUT], ...data}
        }
    };
};

const _updateHBPreStageCallPayload = (
    state,
    {customerId, loanId, isRAL, isRC, tierKey, minAmount, maxAmount},
    preStageDecision,
    loanLimitData
) => {
    let updatedState = _updateUserAppMisc(state, {
        [field.CUSTOMERID]: customerId,
        [field.ISRAL]: isRAL,
        [field.ISRC]: isRC,
        [field.LOANID]: loanId,
        [field.PRESTAGEDECISION]: preStageDecision ? preStageDecision : null,
        [field.MINAMOUNT]: minAmount,
        [field.MAXAMOUNT]: maxAmount,
        [field.TIERKEY]: tierKey
    });
    if (loanLimitData !== undefined) {
        updatedState = _updateLoanLimits(updatedState, {
            [field.LOAN_LIMITS__MAX_AMOUNT]: loanLimitData.maxAmount,
            [field.LOAN_LIMITS__MIN_AMOUNT]: loanLimitData.minAmount
        });
    }
    return updatedState;
};

const _updateResponsefromHBPostStageCall = (state, data) => {
    return _updateUserAppMisc(state, {
        [field.ISKYC]: data.Response.kyc,
        [field.POSTSTAGEDECISION]: data.Response.Result
    });
};

const emailLoginSuccess = (state, action) => {
    return _updateUserAppInfo(state, {
        [field.EMAIL]: action.payload.email,
        [field.EMAIL_STATE_LOGIN_TIMESTAMP]: new Date().getTime()
    });
};

const resetApplicationReducer = (state, action) => {
    return _.cloneDeep(initialState);
};

const updateReapplyUserData = (state, action) => {
    let response = action.payload.result.data;
    state = {
        ...state,
        [field.USERAPPINFO]: {
            ...state[field.USERAPPINFO],
            [field.FIRSTNAME]: response.firstname,
            [field.LASTNAME]: response.lastname,
            [field.EMAIL]: response.email,
            [field.PHONE_NUMBER]: response.phone_number,
            [field.IS_MOBILE]: response.is_mobile,
            [field.ADDRESS]: response.address,
            [field.ADDRESS2]: response.address2,
            [field.CITY]: response.city,
            [field.STATE]: response.state,
            [field.ZIP]: response.zip,
            [field.EMPLOYER_NAME]: response.employer,
            [field.INCOME_TYPE]: response.incomeType,
            [field.PAY_FREQUENCY]: response.payFrequency,
            [field.PAYMENT_TYPE]: response.payType,
            [field.USERID]: response.userId,
            [field.SSN]: !isForaCredit() ? response.ssnLastFour : null,
            [field.ROUTING_NUMBER]: response.routingNumber,
            [field.BANK_NAME]: response.bankName,
            [field.ACCOUNT_NUMBER]: response.accountNumber,
            [field.ACCOUNT_TYPE]: response.accountType
        }
    };
    return state;
};

const updateRALApplicationData = (state, action) => {
    let updatedState = _updateHBPreStageCallPayload(
        state,
        action.payload.data.preStageHBPayload,
        action.payload.data.Response.Result,
        action.payload.data.loanLimitData
    );
    updatedState = _updateUserAppMisc(updatedState, {
        [field.IS_ORGANIC]: true
    });
    return updatedState;
};

const updateDMData = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.IS_PROMOCODE_VALID]: action.payload.data.isPromoCodeValid,
        [field.DM_TIERKEY_MESSAGE]: action.payload.data.message,
        [field.TIERKEY]: action.payload.data.tierKey,
        [field.APPLY_LINK_CLICKED]: true,
        [field.PROMO_CODE]: action.payload.data.promoCode,
        [field.PROMO_CODE_ORG_ID]: action.payload.data.lmsCustomerPromotions ? action.payload.data.lmsCustomerPromotions.organizationId : null,
        [field.DM_PROMO_CODE_AMOUNT]: action.payload.data.loanAmount
    });
};

const saveDMCodeData = (state, action) => {
    let updatedState;
    if (action.payload.data.message === null) {
        updatedState = _updateUserAppMisc(state, {
            [field.DM_TIERKEY_MESSAGE]: "",
            [field.IS_PROMOCODE_VALID]: true,
            [field.TIERKEY]: action.payload.data.lmsCustomerPromotions.tierKey,
            [field.APPLY_LINK_CLICKED]: true,
            [field.OFFER_PAGE_VALIDATED]: true,
            [field.DM_CALCULATION_MODEL]: action.payload.data.lmsCustomerPromotions.calculationModel,
            [field.PROMO_CODE]: action.payload.data.lmsCustomerPromotions.promoCode,
            [field.PROMO_CODE_ORG_ID]: action.payload.data.lmsCustomerPromotions.organizationId,
            [field.APPCODE]: action.payload.data.lmsCustomerPromotions.promoCode,
            [field.IS_ORGANIC]: true
        });
        updatedState = _updateUserAppInfo(updatedState, {
            [field.FIRSTNAME]: action.payload.data.lmsCustomerPromotions.firstName,
            [field.LASTNAME]: action.payload.data.lmsCustomerPromotions.lastName,
            [field.ADDRESS]: action.payload.data.lmsCustomerPromotions.address,
            [field.ADDRESS2]: action.payload.data.lmsCustomerPromotions.address2,
            [field.CITY]: action.payload.data.lmsCustomerPromotions.city,
            [field.STATE]: action.payload.data.lmsCustomerPromotions.state,
            [field.ZIP]: action.payload.data.lmsCustomerPromotions.zip,
            [field.APPCODE]: action.payload.data.lmsCustomerPromotions.promoCode,
            [field.AMOUNT]: action.payload.data.lmsCustomerPromotions.loanAmount,
            [field.EMAIL]: action.emailValue
        });
    } else {
        updatedState = _updateUserAppMisc(state, {
            [field.DM_TIERKEY_MESSAGE]: action.payload.data.message,
            [field.IS_PROMOCODE_VALID]: false
        });
    }

    return updatedState;
};

const clearOfferPageValidated = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.OFFER_PAGE_VALIDATED]: false
    });
};

const clearTierKeyMessage = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.DM_TIERKEY_MESSAGE]: undefined,
        [field.IS_PROMOCODE_VALID]: true
    });
};

const clearDMPromoCodeDetails = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.DM_TIERKEY_MESSAGE]: "",
        [field.PROMO_CODE]: null,
        [field.IS_PROMOCODE_VALID]: null,
        [field.DM_PROMO_CODE_AMOUNT]: null
    });
}

const saveIsPromocodeValid = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.IS_PROMOCODE_VALID]: action.payload,
    });
}

const updateUserAppInfoByApiApplicationResponse = (state, action) => {
    let updatedState = _updateUserAppInfo(state, action.payload.response.data);
    if (action.payload.response.data.tierKey != null) {
        updatedState = _updateUserAppMisc(updatedState, {
            [field.TIERKEY]: action.payload.response.data.tierKey
        });
        updatedState = _updateUserSubIdTracking(updatedState, getSubIdTrackingInitialState());
    }

    return updatedState;
};

const savePhoneNumberUpdate = (state, action) => {
    return _updateUserAppInfo(state, {
        [field.PHONE_NUMBER]: action.payload
    });
}

const updateUserAppInfobyStateInfoSuccess = (state, action) => {
    let updatedState = _updateUserAppInfo(state, action.payload.response.data);

    if (action.payload.response.data.tierKey != null) {
        updatedState = _updateUserAppMisc(updatedState, {
            [field.TIERKEY]: action.payload.response.data.tierKey
        });
    }
    //Set is_organic true for oragnic flow after the state info page success
    updatedState = _updateUserAppMisc(updatedState, {
        [field.IS_ORGANIC]: true
    });

    return updatedState;
};

const createPasswordSuccess = (state, action) => {
    let updatedState = _updateUserAppInfo(state, {
        [field.USERID]: action.payload.response.data.id
    });
    updatedState = _updateUserAppMisc(updatedState, {
        [field.ISPASSWORDTEMPORARY]: false
    });

    return updatedState;
};

const updateTermsAgreeSuccess = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.ISTERMSAGREED]: true
    });
};

const updateUserAppInfoByField = (state, action) => {
    return _updateUserAppInfo(state, {
        [action.fieldName]: [action.fieldValue]
    });
};

const getExternalCustomerAppInfoSuccess = (state, action) => {
    let responseData = action.payload.response.data;
    let nmi;
    if (responseData.nmi !== (undefined || null || "")) {
        nmi = responseData.nmi;
    } else if (responseData.grossmonthly !== "") {
        nmi = responseData.grossmonthly;
    } else {
        nmi = null;
    }

    let userAppDetailstoUpdate = {
        [field.FIRSTNAME]: responseData.firstname,
        [field.LASTNAME]: responseData.lastname,
        [field.EMAIL]: responseData.email,
        [field.PAYCHECK_AMOUNT]: responseData.paycheckAmount,
        [field.NMI]: nmi,
        [field.PAY_FREQUENCY]: responseData.payfrequency,
        [field.STATE]: responseData.state,
        [field.USERID]: responseData.userId,
        [field.ID]: responseData.userAppInfoId
    };

    if (responseData.customerAppInformation != null) {
        let customerAppInformation = responseData.customerAppInformation;
        let phoneNumber = customerAppInformation.cellphone
            ? customerAppInformation.cellphone
            : customerAppInformation.homephone;

        userAppDetailstoUpdate = {
            ...userAppDetailstoUpdate,
            ...{
                [field.PHONE_NUMBER]: phoneNumber,
                [field.ACCOUNT_NUMBER]: customerAppInformation.accountNumber,
                [field.ACCOUNT_TYPE]: customerAppInformation.accountType,
                [field.BANK_NAME]: customerAppInformation.bankName,
                [field.EMPLOYER_NAME]: customerAppInformation.employer,
                [field.INCOME_TYPE]: customerAppInformation.incomeType,
                [field.LAST_PAY_DATE]: customerAppInformation.lastPayDate,
                [field.NEXT_PAY_DATE]: customerAppInformation.nextPayDate,
                [field.PAY_FREQUENCY]: customerAppInformation.payFrequency,
                [field.PAYMENT_TYPE]: customerAppInformation.payType,
                [field.ROUTING_NUMBER]: customerAppInformation.routingNumber
            }
        };
    }

    let isShortform = null;
    if (responseData.isExternal === 1) {
        isShortform = responseData.isShortform === 1;
    }

    let updatedState = _updateUserAppInfo(state, userAppDetailstoUpdate);
    updatedState = _updateUserAppMisc(updatedState, {
        [field.CUSTOMERID]: responseData.customerId,
        [field.IS_ORGANIC]: responseData.isExternal !== 1,
        [field.IS_SHORTFORM]: isShortform,
        [field.ISKYC]: responseData.isKYC
    });

    // if (action.payload.response.data.customerAppInformation != null) {
    //     updatedState = _updateUserAppInfo(updatedState, action.payload.response.data.customerAppInformation);
    // }
    if (responseData.loanLimitData) {
        updatedState = _updateLoanLimits(updatedState, {
            [field.LOAN_LIMITS__MAX_AMOUNT]: responseData.loanLimitData.maxAmount,
            [field.LOAN_LIMITS__MIN_AMOUNT]: responseData.loanLimitData.minAmount
        });
    }

    return updatedState;
};

/**
 * Update response data from pre stage hotbox check.
 */
const hbPreStageCallSuccess = (state, action) => {
    return _updateHBPreStageCallPayload(
        state,
        action.payload.response.data.preStageHBPayload,
        action.payload.response.data.Response.Result,
        action.payload.response.data.loanLimitData
    );
};

const hbPreAndPostStageCallFail = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.HB_PRE_AND_POST_STAGE_FAIL]: true
    });
};

const resetHBPreAndPostStageCallFail = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.HB_PRE_AND_POST_STAGE_FAIL]: null
    });
};



const hbPostStageCallSuccess = (state, action) => {
    return _updateResponsefromHBPostStageCall(state, action.payload.response.data);
};

/**
 * Update application with JWT HB Payload values
 */
const updateJWTPayloadSuccess = (state, action) => {
    const {
        customerId, loanId, isRAL, isRC, tierKey, minAmount, maxAmount, ssnLastFour, email
    } = action.payload.data;
    return _updateUserAppMisc(state, {
        [field.CUSTOMERID]: customerId,
        [field.ISRAL]: isRAL,
        [field.ISRC]: isRC,
        [field.LOANID]: loanId,
        [field.MINAMOUNT]: minAmount,
        [field.MAXAMOUNT]: maxAmount,
        [field.TIERKEY]: tierKey,
        [field.SSN]: ssnLastFour,
        [field.EMAIL]: email
    });
};

const getLandingPageSuccess = (state, action) => {
    let updatedState = _.cloneDeep(state);
    let updateUserAppInfoContent = {};
    let updateUserAppMiscContent = {};
    let updateAuthContent = {};

    if (routeutility.isAnyOfApplicationPage(action.payload.response.data.landingPage)) {
        if (action.payload.response.data.customerId != null) {
            updateUserAppMiscContent[field.CUSTOMERID] = action.payload.response.data.customerId;
        }

        updateUserAppMiscContent[field.IS_ORGANIC] = action.payload.response.data.isOrganic;
        updateUserAppMiscContent[field.IS_SHORTFORM] = action.payload.response.data.isShortform;
        updateUserAppMiscContent[field.ISRC] = action.payload.response.data.isRC;
        updateUserAppMiscContent[field.ISRAL] = action.payload.response.data.isRAL;

        try{
            let isRC = action.payload.response.data.isRC === true;
            let isRAL = action.payload.response.data.isRAL === true;
            let isOrganic = action.payload.response.data.isOrganic === true;
            let hasCustomerId = action.payload.response.data.customerId !== null;
            let hasAppCode = action.payload.response.data.userAppInformationDTO.appCode !== null;
            let hasCustomerPromotion = action.payload.response.data.dmValidationRespDTO !== null && action.payload.response.data.dmValidationRespDTO.lmsCustomerPromotions !== null;
            // If DM customer, make sure to save DM tierkey - specifically for customers that drop off and come back after personal info and before first hb check.
            if (hasAppCode && isOrganic && hasCustomerPromotion && !isRAL && !isRC && !hasCustomerId) {
                updateUserAppMiscContent[field.ISRC] = false;
                updateUserAppMiscContent[field.PROMO_CODE] = action.payload.response.data.userAppInformationDTO.appCode;
                updateUserAppMiscContent[field.APPCODE] = action.payload.response.data.userAppInformationDTO.appCode;
                updateUserAppMiscContent[field.TIERKEY] = action.payload.response.data.dmValidationRespDTO.lmsCustomerPromotions.tierKey;
                updateUserAppMiscContent[field.IS_PROMOCODE_VALID] = true;
            }
        } catch (error) {
            console.log("Error setting customer promo data.");
        }

        if (action.payload.response.data.isTermsAgreed != null) {
            updateUserAppMiscContent[field.ISTERMSAGREED] = action.payload.response.data.isTermsAgreed;
        }

        if (action.payload.response.data.isPasswordTemporary != null) {
            updateUserAppMiscContent[field.ISPASSWORDTEMPORARY] = action.payload.response.data.isPasswordTemporary;
            updateAuthContent[field.IS_PASSWORD_TEMPORARY] = action.payload.response.data.isPasswordTemporary;
        }

        if (action.payload.response.data.userAppInformationDTO != null) {
            updateUserAppInfoContent = _.cloneDeep(action.payload.response.data.userAppInformationDTO);
        }

        if (action.payload.response.data.customerAppInformationDTO != null) {
            let customerAppInfoDTO = _.cloneDeep(action.payload.response.data.customerAppInformationDTO);
            let phoneNumber = customerAppInfoDTO.cellphone
                ? customerAppInfoDTO.cellphone
                : customerAppInfoDTO.homephone;
            delete customerAppInfoDTO.homephone;
            delete customerAppInfoDTO.cellphone;
            if (action.payload.response.data.isOrganic === true) {
                delete customerAppInfoDTO.paycheckAmount;
            }
            customerAppInfoDTO[field.PHONE_NUMBER] = phoneNumber;
            updateUserAppInfoContent = {
                ...updateUserAppInfoContent,
                ...customerAppInfoDTO
            };
        }

        if (
            action.payload.response.data.preStagehotboxResponse != null &&
            action.payload.response.data.preStagehotboxResponse.preStageHBPayload != null
        ) {
            updatedState = _updateHBPreStageCallPayload(
                updatedState,
                action.payload.response.data.preStagehotboxResponse.preStageHBPayload,
                action.payload.response.data.preStagehotboxResponse.Response.Result,
                action.payload.response.data.preStagehotboxResponse.loanLimitData
            );
        }

        if (action.payload.response.data.postStageHotboxResponse != null) {
            updatedState = _updateResponsefromHBPostStageCall(
                updatedState,
                action.payload.response.data.postStageHotboxResponse
            );
        }

        if (action.payload.response.data.jwthbPreStagePayload != null) {
            updatedState = _updateHBPreStageCallPayload(
                updatedState,
                action.payload.response.data.jwthbPreStagePayload,
                null
            );
        }

        if(action.payload.response.data.userLoanAppInformationDTO != null) {
            updatedState = _updateUserLoanAppProgress(updatedState, {
                [field.USERREQUESTEDAMOUNT]: action.payload.response.data.userLoanAppInformationDTO.userRequestedAmount
            });
        }

        updatedState = _updateUserAppInfo(updatedState, updateUserAppInfoContent);
        updatedState = _updateUserAppMisc(updatedState, updateUserAppMiscContent);
        updatedState = _updateAuthInfo(updatedState, updateAuthContent);
    } else if (routeutility.isAnyOfDashboardPage(action.payload.response.data.landingPage)) {

        if (
            action.payload.response.data.customerActiveLoanDetails != null &&
            action.payload.response.data.customerActiveLoanDetails.status === "Pending"
        ) {
            if (
                action.payload.response.data.preStagehotboxResponse != null &&
                action.payload.response.data.preStagehotboxResponse.preStageHBPayload != null
            ) {
                updatedState = _updateUserAppMisc(updatedState, {
                    [field.TIERKEY]: action.payload.response.data.preStagehotboxResponse.preStageHBPayload.tierKey
                });
            }

            if (action.payload.response.data.jwthbPreStagePayload != null) {
                updatedState = _updateHBPreStageCallPayload(
                    updatedState,
                    {
                        tierKey: action.payload.response.data.jwthbPreStagePayload.tierKey
                    },
                    null
                );
            }
        }

        if (action.payload.response.data.userAppInformationDTO != null) {
            updateUserAppInfoContent = _.cloneDeep(action.payload.response.data.userAppInformationDTO);
            let info = {
                id: updateUserAppInfoContent.id,
                userId: updateUserAppInfoContent.userId,
                isProductChangePopupShown: updateUserAppInfoContent.isProductChangePopupShown,
                hasNewFees: updateUserAppInfoContent.hasNewFees

            }
            updatedState = _updateUserAppInfo(updatedState, info);
        }

        let updateContent = {
            [field.IS_ORGANIC]: action.payload.response.data.isOrganic,
            [field.IS_SHORTFORM]: action.payload.response.data.isShortform,
            [field.ISRC]: action.payload.response.data.isRC,
            [field.ISRAL]: action.payload.response.data.isRAL
        };
        updatedState = _updateUserAppMisc(updatedState, updateContent);
    }

    if(action.payload.response.data.subIdTrackingDTO != null) {
        updatedState = _updateUserSubIdTracking(updatedState, action.payload.response.data.subIdTrackingDTO);
        if(action.payload.response.data.subIdTrackingDTO.tierKey && action.payload.response.data.subIdTrackingDTO.tierKeyMatchesOrganization === true) {
            updatedState = _updateUserAppMisc(updatedState, {
                [field.TIERKEY]: action.payload.response.data.subIdTrackingDTO.tierKey
            });
        }
    }

    return updatedState;
};

const getKYCQuestionsSuccess = (state, action) => {
    return {
        ...state,
        ...{
            [field.KYC]: {
                ...state[field.KYC],
                ...{
                    [field.KYC_QUESTIONS]: action.payload.response.data.kycQuestions,
                    [field.KYC_REMAINING_SECONDS]: action.payload.response.data.kycRemainingSeconds,
                    [field.IS_KYC_COMPLETED]: action.payload.response.data.isKYCCompleted
                }
            }
        }
    };
};

const saveVerifyPhoneSuccess = (state, action) => {
    let updatedState = _updateUserAppMisc(state, {
        [field.ISCONTACTUPDATED]: true,
        [field.CONTACT_INFO_IS_MOBILE]: action.payload.response.isMobile,
    });

    updatedState = _updateAuthInfo(updatedState, {
        [field.ISCONTACTUPDATED]: true
    });

    return updatedState;
};

const saveVerificationStatus = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.IS_PHONE_VERIFIED]: action.payload.response.data.phoneVerified,
        [field.IS_PHONE_VERIFICATION_SKIPPED]: action.payload.response.data.phoneVerificationSkipped,
        [field.PHONE_VERIFICATION_HAS_MOBILE]: action.payload.response.data.hasMobile
    });
};

const saveVerificationCodeSuccess = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.PHONE_VERIFICATION_STATUS_MESSAGE]: action.payload.response.data.message,
        [field.PHONE_VERIFICATION_SUBMITTED]: Date.now()
    });
};

const submitVerificationCodeSuccess = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.PHONE_VERIFICATION_STATUS_MESSAGE]: action.payload.response.data.message,
        [field.PHONE_VERIFICATION_SUBMITTED]: Date.now()
    });
};

const saveCreateAccountSuccess = (state, action) => {
    let updatedState = _updateAuthInfo(state, {
        [field.IS_PASSWORD_TEMPORARY]: false
    });
    if (action.payload.email && action.payload.email !== state[field.USERAPPINFO][field.EMAIL]) {
        updatedState = _updateUserAppInfo(updatedState, {
            [field.EMAIL]: action.payload.email
        });
    }

    return updatedState;
};

const submitPasswordVerificationSuccess = (state, action) => {
    let updatedState = _updateUserAppMisc(state, {
        [field.MFA_PASSWORD_VERIFICATION_STATUS]: action.payload.response.data
    });
    if(action.payload.response.data === false){
        updatedState = _updateUserAppMisc(state, {
            [field.MFA_PASSWORD_ATTEMPT]: state[field.USERAPPMISC][field.MFA_PASSWORD_ATTEMPT]+1
        });
    }

    return updatedState;
};

const resetMfaPasswordVerificationState = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.MFA_PASSWORD_VERIFICATION_STATUS]: false
    });
}

const checkIfRCForMfaLogin = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.IS_RC_LOGIN_FOR_MFA]: action.payload.isRC
    });
}

const mfaVerificationCodeResponse = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.MFA_CODE_SUBMIT_RESPONSE]: new Date().getTime()
    });
}

const sendMfaVerificationCodeSuccess = (state, action) => {
    let updatedState = _updateUserAppMisc(state, {
        [field.MFA_VERIFICATION_CODE_STATUS]: true,
        [field.MFA_VERIFICATION_MASKED_CONTACT]: action.payload.response.data.contact,
        [field.MFA_VERIFICATION_CONTACT_TYPE]: action.payload.response.data.contactType
    });
    if(!!action.payload.response.data.mfaContactResendDTO &&
        !!action.payload.response.data.mfaContactResendDTO.contact){
        updatedState = _updateUserAppMisc(state, {
            [field.MFA_VERIFICATION_CONTACT_TYPE]: action.payload.response.data.mfaContactResendDTO.contactType,
            [field.MFA_VERIFICATION_MASKED_CONTACT]: action.payload.response.data.mfaContactResendDTO.contact
        });
    }
    return updatedState;
};

const sendMfaSMSServiceDown = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.MFA_SMS_SERVICE_DOWN]: action.payload.isSMSServiceDown
    });
}

const submitMfaVerificationSuccess = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.MFA_SUBMIT_VERIFICATION_CODE_STATUS]: true,
    });
};

const isMfaEnabled = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.USER_MFA_ENABLED]: action.payload.response.data.isMfaEnabled,
        [field.USER_MFA_LAST_ENABLED_DATE]: action.payload.response.data.mfaLastModifiedDateTime,
        [field.USER_PASSWORD_LAST_MODIFIED_DATE]: action.payload.response.data.passwordLastModified,
    });
}

const isMfaEnabledForOrg = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.ORG_MFA_ENABLED]: action.payload.response.data
    });
}

const isMfaDisabled = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.MFA_DISABLE_STATUS]: action.payload.response.data
    });
}

const showMfaNotificationSuccess = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.SHOW_MFA_NOTIFICATION]: action.payload.response.data
    });
}

const sendMfaVerificationCodeErrorMessage = (state, action) => {
    let updatedState = _updateUserAppMisc(state, {
        [field.MFA_VERIFICATION_CODE_ERROR]: action.payload.response.data.message
    });
    if(!!action.payload.response.data.contact){
        updatedState = _updateUserAppMisc(state, {
            [field.MFA_VERIFICATION_CODE_ERROR]: action.payload.response.data.message,
            [field.MFA_VERIFICATION_MASKED_CONTACT]: action.payload.response.data.contact
        });
    }

    return updatedState;
}

const resetMfaVerificationState = (state,action) => {
    return _updateUserAppMisc(state, {
        [field.MFA_VERIFICATION_CODE_ERROR]: "",
        [field.MFA_SUBMIT_VERIFICATION_CODE_STATUS]: false,
        [field.MFA_VERIFICATION_CONTACT_TYPE]: null,
        [field.MFA_VERIFICATION_MASKED_CONTACT]: null
    });
}

const resetMfaPasswordVerified = (state,action) => {
    return _updateUserAppMisc(state, {
        [field.MFA_PASSWORD_VERIFICATION_STATUS]: false,
        [field.MFA_PASSWORD_ATTEMPT]: 0
    });
}

const sendOtpLoginEmailSuccess = (state, action) => {
    return _updateUserAppMisc(state, {
        [field.OTP_LOGIN_SEND_COUNT]: state[field.USERAPPMISC][field.OTP_LOGIN_SEND_COUNT] + 1,
        [field.INITIAL_OTP_LOGIN_VERIFICATION_SENT]: true
    });
};

const submitOtpLoginVerificationCodeSuccess = (state, action) => {
    return _updateUserAppInfo(state, {
        [field.USERID]: action.payload.response.data.userId
    });
};

const submitOtpLoginVerificationCodeIncorrect = (state) => {
    return _updateUserAppMisc(state, {
        [field.OTP_LOGIN_ATTEMPT_COUNT]: state[field.USERAPPMISC][field.OTP_LOGIN_ATTEMPT_COUNT] + 1,
    });
};

const resetOtpLoginCounts = (state,action) => {
    return _updateUserAppMisc(state, {
        [field.OTP_LOGIN_SEND_COUNT]: 0,
        [field.OTP_LOGIN_ATTEMPT_COUNT]: 0,
        [field.INITIAL_OTP_LOGIN_VERIFICATION_SENT]: false
    });
}

const saveBankingInfoForRC = (state, action) => {
    return _updateUserAppInfo(state, {
        [field.BANK_NAME]: action.payload.response.data.bankName,
        [field.ROUTING_NUMBER]: action.payload.response.data.routingNumber,
        [field.ACCOUNT_NUMBER]: action.payload.response.data.accountNumber,
        [field.ACCOUNT_TYPE]: action.payload.response.data.accountType,
        [field.BANK_INSTITUTION_CODE]: action.payload.response.data.institutionNumber,
        [field.BANK_TRANSIT_CODE]: action.payload.response.data.transitNumber,
    });
};

const saveKYCQASuccess = (state, action) => {
    return {
        ...state,
        ...{
            [field.KYC]: {
                ...state[field.KYC],
                ...{
                    [field.KYC_SUBMISSION_RESPONSE]: action.payload.response.data.trim()
                }
            }
        }
    };
};

const resetKYCRemainingSeconds = (state, action) => {
    return {
        ...state,
        ...{
            [field.KYC]: {
                ...state[field.KYC],
                ...{
                    [field.KYC_REMAINING_SECONDS]: null
                }
            }
        }
    };
};

const getKYCReapplyTimeSuccess = (state, action) => {
    return {
        ...state,
        ...{
            [field.KYC]: {
                ...state[field.KYC],
                ...{
                    [field.KYC_REAPPLY_TIME]: action.payload.response.data
                }
            }
        }
    };
};

const creditFreshSupportedSucceess = (state, action) => {
    return {
        ...state,
        ...{
            [field.USERAPPMISC]: {
                ...state[field.USERAPPMISC],
                ...{
                    [field.IS_CREDITFRESH_SUPPORTED]: action.payload.response
                }
            }
        }
    };
};
const setCreditFreshLimit = (state, action) => {
    return {
        ...state,
        ...{
            [field.USERAPPMISC]: {
                ...state[field.USERAPPMISC],
                ...{
                    [field.CREDITFRESH_LIMIT]: action.payload.response
                }
            }
        }
    };
};


const savePersonalInfoDataProgress = (state, action) => {
    return {
        ...state,
        [field.USERAPPINFO]: {
            ...state[field.USERAPPINFO],
            [field.FIRSTNAME]: action.payload.data.firstname,
            [field.LASTNAME]: action.payload.data.lastname,
            [field.EMAIL]: action.payload.data.email,
            [field.PHONE_NUMBER]: action.payload.data.phone_number,
            [field.IS_MOBILE]: action.payload.data.is_mobile,
            [field.ADDRESS]: action.payload.data.address,
            [field.ADDRESS2]: action.payload.data.address2,
            [field.CITY]: action.payload.data.city,
            [field.STATE]: action.payload.data.state,
            [field.RESIDENCY_STATUS]: action.payload.data.residencyStatus,
            [field.ZIP]: action.payload.data.zip,
            [field.USERID]: action.payload.data.userId,
            [field.IS_RENTAL]: action.payload.data.isRental,
            [field.YEARS]: action.payload.data.years,
            [field.ORGANIZATION.ORGANIZATION_ID]: action.payload.data.organizationId,
            [field.IS_RECEIVE_OFFERS]: action.payload.data.receiveOffers,
            [field.EXISTING_FLEX_LOAN]: action.payload.data.hasExistingFlexLoan
        }
    };
};

const saveEmploymentInfoData = (state, action) => {
    return {
        ...state,
        [field.USERAPPINFO]: {
            ...state[field.USERAPPINFO],
            [field.PAYCHECK_AMOUNT]: action.payload.paycheckAmount,
            [field.PAY_FREQUENCY]: action.payload.payFrequency,
            [field.INCOME_TYPE]: action.payload.incomeType,
            [field.PAYMENT_TYPE]: action.payload.paymentType
        }
    };
};

const saveProductChangePopupAcknowledgementSuccess = (state, action) => {
    return {
        ...state,
        [field.USERAPPINFO]: {
            ...state[field.USERAPPINFO],
            [field.ISPRODUCTCHANGEPOPUPSHOWN]: action.payload.response.data.isProductChangePopupShown
        }
    };
};

const saveOptOutPreferencesSuccess = (state, action) => {
    return _updateUserOptOutPreferences(state, action.payload.response.data);
};

const savePartnerParamValidationSuccess = (state, action) => {
    return {
        ...state,
        [field.USERAPPMISC]: {
            ...state[field.USERAPPMISC],
            [field.TIERKEY]: action.payload.tierkey,
            [field.EXTERNAL_REFERENCE_ID]: action.payload.externalReferenceId
        }
    };
};

export default function applicationReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_REAPPLY_APPLICATION_DATA_SUCCESS:
            return updateReapplyUserData(state, action);
        case actionTypes.SUBMIT_RAL_APPLICATION_DATA_SUCCESS:
            return updateRALApplicationData(state, action);

        case actionTypes.VALIDATE_DM_CODE_SUCCESS:
            return updateDMData(state, action);

        case actionTypes.UPDATE_USER_LOGIN_INFO:
            return _updateUserLoginInfo(state, action.payload);

        case actionTypes.EMAIL_LOGIN_SUCCESS:
            return emailLoginSuccess(state, action);

        case actionTypes.RESET_APPLICATION_REDUCER:
            return resetApplicationReducer(state, action);

        case actionTypes.UPDATE_USERAPPINFO:
            return _updateUserAppInfo(state, action.payload);

        case actionTypes.UPDATE_USERAPPMISC:
            return _updateUserAppMisc(state, action.payload);

        case actionTypes.UPDATE_USERLOANAPPPROGRESS:
            return _updateUserLoanAppProgress(state, action.payload);

        case actionTypes.UPDATE_APPLICATION:
            return updateUserAppInfoByField(state, action);

        case actionTypes.START_APPLICATION_SUCCESS:
            return updateUserAppInfobyStateInfoSuccess(state, action);

        case actionTypes.SAVE_APPLICATION_SUCCESS:
            return updateUserAppInfoByApiApplicationResponse(state, action);

        case actionTypes.UPDATE_PHONE_NUMBER:
            return savePhoneNumberUpdate(state, action);

        case actionTypes.START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT_SUCCESS:
            return saveProductChangePopupAcknowledgementSuccess(state, action);

        case actionTypes.START_CREATEPASSWORD_SUCCESS:
            return createPasswordSuccess(state, action);

        case actionTypes.SAVE_CONSENT_DOCUMENT_SUCCESS:
            return updateTermsAgreeSuccess(state, action);

        case actionTypes.START_HB_PRE_STAGE_CALL_SUCCESS:
            return hbPreStageCallSuccess(state, action);

        case actionTypes.START_HB_POST_STAGE_CALL_SUCCESS:
            return hbPostStageCallSuccess(state, action);

        case actionTypes.START_HB_CALL_FAIL:
            return hbPreAndPostStageCallFail(state, action);

        case actionTypes.RESET_HB_CALL_FAIL:
            return resetHBPreAndPostStageCallFail(state, action);

        case actionTypes.VALIDATE_EXTRACT_JWTHB_TOKEN_SUCCESS:
            return updateJWTPayloadSuccess(state, action);

        case actionTypes.GET_EXTERNAL_CUSTOMER_APP_INFO_SUCCESS:
            return getExternalCustomerAppInfoSuccess(state, action);

        case actionTypes.GET_LANDING_PAGE_SUCCESS:
            return getLandingPageSuccess(state, action);

        case actionTypes.GET_KYC_QUESTIONS_SUCCESS:
            return getKYCQuestionsSuccess(state, action);

        case actionTypes.SAVE_KYC_QA_SUCCESS:
            return saveKYCQASuccess(state, action);

        case actionTypes.RESET_KYC_REMAINING_SECONDS:
            return resetKYCRemainingSeconds(state, action);

        case actionTypes.GET_KYC_REAPPLY_TIME_SUCCESS:
            return getKYCReapplyTimeSuccess(state, action);

        case actionTypes.IS_CREDITFRESH_SUPPORTED_SUCCESS:
            return creditFreshSupportedSucceess(state, action);

            case actionTypes.SET_CREDITFRESH_LIMIT:
            return setCreditFreshLimit(state, action);

        case actionTypes.SAVE_URL_PARAMS:
            return _saveUrlparams(state, action.payload);

        case actionTypes.SAVE_OPTOUT_PREFERENCES_SUCCESS:
            return saveOptOutPreferencesSuccess(state, action);

        case actionTypes.VERIFY_PHONE_SUCCESS:
            return saveVerifyPhoneSuccess(state, action);

        case actionTypes.GET_VERIFICATION_STATUS_SUCCESS:
            return saveVerificationStatus(state, action);

        case actionTypes.SEND_VERIFICATION_CODE_SUCCESS:
            return saveVerificationCodeSuccess(state, action);

        case actionTypes.SUBMIT_VERIFICATION_CODE_SUCCESS:
            return submitVerificationCodeSuccess(state, action);

        case actionTypes.SUBMIT_PASSWORD_VERIFICATION_SUCCESS:
            return submitPasswordVerificationSuccess(state, action);

        case actionTypes.RESET_MFA_PASSWORD_VERIFICATION_STATUS:
            return resetMfaPasswordVerificationState;

        case actionTypes.SEND_MFA_VERIFICATION_CODE_SUCCESS:
            return sendMfaVerificationCodeSuccess(state, action);

        case actionTypes.MFA_SMS_SERVICE_DOWN:
            return sendMfaSMSServiceDown(state, action);

        case actionTypes.CHECK_IS_RC_FOR_MFA_LOGIN:
            return checkIfRCForMfaLogin(state, action);

        case actionTypes.MFA_SUBMIT_CODE_RESPONSE:
            return mfaVerificationCodeResponse(state, action);

        case actionTypes.SUBMIT_MFA_VERIFICATION_SUCCESS:
            return submitMfaVerificationSuccess(state, action);

        case actionTypes.IS_MFA_ENABLED:
            return isMfaEnabled(state, action);

        case actionTypes.IS_MFA_ENABLED_FOR_ORG:
            return isMfaEnabledForOrg(state, action);

        case actionTypes.MFA_DISABLED_SUCCESS:
            return isMfaDisabled(state, action);

        case actionTypes.SHOW_MFA_NOTIFICATION_SUCCESS:
            return showMfaNotificationSuccess(state, action);

        case actionTypes.SEND_MFA_VERIFICATION_CODE_FAIL:
            return sendMfaVerificationCodeErrorMessage(state, action);

        case actionTypes.RESET_MFA_VERIFICATION_ERROR:
            return resetMfaVerificationState(state, action);

        case actionTypes.RESET_MFA_PASSWORD_VERIFIED:
            return resetMfaPasswordVerified(state, action);

        case actionTypes.SEND_OTP_LOGIN_EMAIL_SUCCESS:
            return sendOtpLoginEmailSuccess(state, action);

        case actionTypes.SUBMIT_OTP_LOGIN_VERIFICATION_CODE_SUCCESS:
            return submitOtpLoginVerificationCodeSuccess(state, action);

        case actionTypes.SUBMIT_OTP_LOGIN_VERIFICATION_CODE_INCORRECT:
            return submitOtpLoginVerificationCodeIncorrect(state);

        case actionTypes.RESET_OTP_LOGIN_COUNTS:
            return resetOtpLoginCounts(state, action);

        case actionTypes.SAVE_CREATE_ACCOUNT_SUCCESS:
            return saveCreateAccountSuccess(state, action);

        case actionTypes.GET_BANKING_INFORMATION_FOR_RC_SUCCESS:
            return saveBankingInfoForRC(state, action);

        case actionTypes.SAVE_PERSONAL_INFO_DATA:
            return savePersonalInfoDataProgress(state, action);
        case actionTypes.SAVE_EMPLOYMENT_INFO_DATA:
            return saveEmploymentInfoData(state, action);
        case actionTypes.VALIDATE_DM_APPLICATION_CODE_SUCCESS:
            return saveDMCodeData(state, action);
        case actionTypes.GET_STATE_SELECTION_ORGANIZATION_ID_SUCCESS:
            return {
                ...state,
                // [field.USERAPPMISC]: {
                //     ...state[field.USERAPPMISC],
                //     [field.ORGANIZATION.ORGANIZATION_ID]: action.payload
                // },
                [field.USERAPPINFO]: {
                    ...state[field.USERAPPINFO],
                    [field.EMAIL]: action.payload.email
                },

            };
        case actionTypes.SET_DUPLICATE_ORGANIZATION:
                return {
                    ...state,
                    [field.USERAPPMISC]: {
                    ...state[field.USERAPPMISC],
                    [field.DUPLICATE_ORGANIZATION]: action.payload
                },

            };
        case actionTypes.CLEAR_OFFER_PAGE_VALIDATED:
            return clearOfferPageValidated(state, action)
        case actionTypes.CLEAR_DM_PROMOCODE_DETAILS:
            return clearDMPromoCodeDetails(state, action);

        case actionTypes.CLEAR_TIERKEY_MESSAGE:
            return clearTierKeyMessage(state, action);
        case actionTypes.SAVE_IS_PROMOCODE_VALID:
            return saveIsPromocodeValid(state, action);
        case actionTypes.UPDATE_PAYMENT_SCHEDULE_CHOICES:
            //The below is much easier to trace then using the plethora of utility functions we have above.
            return {
                ...state,
                [field.USERAPPINFO]: {
                    ...state[field.USERAPPINFO],
                    [field.CHOICES]: action.payload
                }
            };
        case actionTypes.SAVE_SUB_ID_TRACKING_SUCCESS:
        case actionTypes.GET_SUB_ID_TRACKING_SUCCESS:
            return _updateUserSubIdTracking(state, action.payload.response.data);
        case actionTypes.VALIDATE_PARTNER_PARAM_SUCCESS:
            return savePartnerParamValidationSuccess(state, action);
        default:
            return state;
    }
}

export const getInitialState = () => {
    return initialState;
};
