import * as actionTypes from "./actionTypes";

/**
 * Reset ach reducer action
 * @returns {{type: string, payload: {}}}
 */
export const resetAchReducer = () => {
    return {
        type: actionTypes.RESET_ACH_REDUCER,
        payload: {}
    };
};

export const getAchSchedulingInfo = loanId => {
    return {
        type: actionTypes.GET_ACH_SCHEDULING_INFO,
        payload: {
            loanId
        }
    };
};

export const getAchSchedulingInfoSuccess = response => {
    return {
        type: actionTypes.GET_ACH_SCHEDULING_INFO_SUCCESS,
        payload: {
            response
        }
    };
};
