import _ from 'lodash'

import * as cookieUtility from '../../shared/utility/cookieutility'
import * as constant from '../../shared/constant/constant'
import {ACH as field} from '../../shared/constant/field'
import * as actionTypes from '../action/actionTypes'

const initialState = {
    [field.ACH_SCHEDULING_INFO] : {
        [field.ACH_SCHEDULING_INFO_BANK_NAME]: null,
        [field.ACH_SCHEDULING_INFO_ROUTING_NUMBER]: null,
        [field.ACH_SCHEDULING_INFO_ACCOUNT_TYPE]: null,
        [field.ACH_SCHEDULING_INFO_ACCOUNT_NUMBER_LAST_4]: null,
        [field.ACH_SCHEDULING_INFO_ELIGIBLE]: null,
        [field.ACH_SCHEDULING_INFO_PAYMENT_DATES]: [],
        [field.ACH_SCHEDULING_INFO_CUT_OFF_TIME]: null
    }
};

/**
 * This function is used in react/src/index.jsx to rehydrate AchState from cookie.
 * @returns {{[p: string]: {}}}
 */
export const loadAchDataFromCookie = () => {
    let loanstate = initialState;
    let mkWebAchData = cookieUtility.getCookie(constant.COOKIE_MKWEBACH_DATA);
    loanstate = {...loanstate, ...mkWebAchData};
    return loanstate;
};

const _setAchReducerCookie = state => {
    let cookieMkwebAchData = _.cloneDeep(state);
    cookieUtility.setCookie(constant.COOKIE_MKWEBACH_DATA, JSON.stringify(cookieMkwebAchData));
};

const resetAchReducer = (state, action) => {
    return _.cloneDeep(initialState);
};

const getAchSchedulingInfoSuccess = (state, action) => {
    return {
        ...state,
        [field.ACH_SCHEDULING_INFO]: action.payload.response.data
    };
}

export default function achReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.RESET_ACH_REDUCER:
            return resetAchReducer(state, action);
        case actionTypes.GET_ACH_SCHEDULING_INFO_SUCCESS:
            return getAchSchedulingInfoSuccess(state, action);

        default:
            return state;
    }
}
