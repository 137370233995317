const API_ERROR_404_MEESAGE = "Record not found.";
const API_ERROR_403_MEESAGE =
    "You dont have authorization to do this, please contact our customer services if otherwise.";
const API_ERROR_401_MEESAGE = "Your session has ended. Please login again.";
const API_ERROR_400_MEESAGE = "Something went wrong. Please try again, or contact our customer service.";
const API_ERROR_500_MESSAGE = "Something went wrong. Please try again, or contact our customer service.";
const API_ERROR_502_MESSAGE = "Something went wrong. Please try again, or contact our customer service.";
const API_ERROR_JWT_TOKEN_EXPIRED = "The link has expired. Please login to continue.";
const API_ERROR_JWT_TOKEN_NO_LONGER_VALID = "The link is no longer valid. Please login to continue.";
const API_ERROR_DOC_UPLOAD_SCAN = "File cannot be uploaded. An unexpected error occurred. Please try again.";
const API_ERROR_DOC_UPLOAD_VIRUS = "File cannot be uploaded. File contents may be corrupt or damaged.";
const API_ERROR_VIRUS_DETECTED = "VIRUS_DETECTED";
const API_ERROR_TIMEOUT = "Network Error";
const EXCEEDED_ACH_CUTOFF_TIME = "Customer has exceeded the cutoff time,Unable to Schedule ACH Payment.";
const DUE_DATE_PASSED = "Customer is not eligible for ACH scheduling";

export const API_ERROR_MESSAGE = {
    API_ERROR_JWT_TOKEN_EXPIRED,
    API_ERROR_JWT_TOKEN_NO_LONGER_VALID,
    API_ERROR_400_MEESAGE,
    API_ERROR_401_MEESAGE,
    API_ERROR_403_MEESAGE,
    API_ERROR_404_MEESAGE,
    API_ERROR_500_MESSAGE,
    API_ERROR_502_MESSAGE,
    API_ERROR_DOC_UPLOAD_SCAN,
    API_ERROR_DOC_UPLOAD_VIRUS,
    API_ERROR_VIRUS_DETECTED,
    API_ERROR_TIMEOUT,
    EXCEEDED_ACH_CUTOFF_TIME,
    DUE_DATE_PASSED
};
