import * as route from "../../shared/constant/route";
import {call, put} from "redux-saga/effects";
import api from "../../axios/api";
import * as achActions from "../action/achActions";
import * as createAndTrackToastMessageAction from "../action/CreateAndTrackToastMessageAction";

export function* getAchSchedulingInfo(action) {
    try {
        let url = route.WEB_API_ACH_SCHEDULING_INFO
            .replace(/:baseLoanId/i, action.payload.loanId);
        let response = yield call(api,{
            method: "get",
            url
        });
        yield put(achActions.getAchSchedulingInfoSuccess(response));
    } catch (error) {
        yield put(createAndTrackToastMessageAction.createAndTrackErrorMessage(error, null));
    }
}
